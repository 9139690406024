import React, { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleLeft,
  faEnvelope,
  faUnlockAlt,
} from "@fortawesome/free-solid-svg-icons";
import {
  Col,
  Row,
  Form,
  Card,
  Button,
  FormCheck,
  Container,
  InputGroup,
} from "@themesberg/react-bootstrap";
import { Link } from "react-router-dom";

import { Routes } from "../routes";
import BgImage from "../assets/img/illustrations/signin.svg";
import { auth, logout } from "../firebase";
import { useHistory } from "react-router-dom";
import { useCookies } from "react-cookie";
import { db } from "../firebase";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import Loader from "react-loader-spinner";

export default () => {
  const history = useHistory();
  const email = useRef();
  const password = useRef();
  const [userProfile, setUserProfile] = useCookies(["name"]);
  const [loading, setLoading] = useState(false);

  const SwalWithBootstrapButtons = withReactContent(
    Swal.mixin({
      customClass: {
        confirmButton: "btn btn-primary me-3",
        cancelButton: "btn btn-gray",
      },
      buttonsStyling: true,
    })
  );

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    var isLoggedIn = false;
    try {
      while (isLoggedIn) {
        try {
          await auth.signInWithEmailAndPassword(
            email.current.value,
            password.current.value
          );
          isLoggedIn = true;
        } catch (err) {
          isLoggedIn = false;
          console.error(err);
        }
      }
      const userProfileRef = await db
        .collection("user_profile")
        .where("email", "==", email.current.value)
        .get();
      const user = userProfileRef.docs[0].data();
      setUserProfile("name", user.full_name);
      setLoading(false);
      history.replace("/dashboard");
    } catch (err) {
      console.error(err);
      //alert("Authentication failed");
      await SwalWithBootstrapButtons.fire(
        "Authentication failed!",
        "Invalid email or password, please double check and try again!",
        "error"
      );
    }
  };

  const resetPassword = async () => {
    setLoading(true);
    //console.log("handleLogin: " + email.current.value + " | " + password.current.value);
    console.log("resetPassword: " + email.current.value);
    try {
      if (email.current.value !== "" && email.current.value !== null) {
        await auth.sendPasswordResetEmail(email.current.value);
        await SwalWithBootstrapButtons.fire(
          "Check email!",
          "Check your inbox for the reset password link!",
          "success"
        );
      } else {
        await SwalWithBootstrapButtons.fire(
          "Missing email!",
          "Valid email is required to reset the password!",
          "error"
        );
      }
      setLoading(false);
    } catch (err) {
      console.error(err.message);
      setLoading(false);
      await SwalWithBootstrapButtons.fire(
        "Invalid email!",
        "We couldn't find this email in our system, please double check!",
        "error"
      );
    }
  };

  return (
    <main>
      <section className="d-flex align-items-center my-5 mt-lg-6 mb-lg-5">
        <Container>
          {/* <p className="text-center">
            <Card.Link as={Link} to={Routes.DashboardOverview.path} className="text-gray-700">
              <FontAwesomeIcon icon={faAngleLeft} className="me-2" /> Back to homepage
            </Card.Link>
          </p> */}
          <Row
            className="justify-content-center form-bg-image"
            style={{ backgroundImage: `url(${BgImage})` }}
          >
            <Col
              xs={12}
              className="d-flex align-items-center justify-content-center"
            >
              <div className="bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                <div className="text-center text-md-center mb-4 mt-md-0">
                  <h3 className="mb-0">Sign in to SA Sprinter</h3>
                </div>
                <Form className="mt-4">
                  <Form.Group id="email" className="mb-4">
                    <Form.Label>Your Email</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faEnvelope} />
                      </InputGroup.Text>
                      <Form.Control
                        autoFocus
                        required
                        type="email"
                        placeholder="example@company.com"
                        ref={email}
                      />
                    </InputGroup>
                  </Form.Group>
                  <Form.Group>
                    <Form.Group id="password" className="mb-4">
                      <Form.Label>Your Password</Form.Label>
                      <InputGroup>
                        <InputGroup.Text>
                          <FontAwesomeIcon icon={faUnlockAlt} />
                        </InputGroup.Text>
                        <Form.Control
                          required
                          type="password"
                          placeholder="Password"
                          ref={password}
                        />
                      </InputGroup>
                    </Form.Group>
                    {/* <div className="d-flex justify-content-between align-items-center mb-4">
                      <Form.Check type="checkbox">
                        <FormCheck.Input id="defaultCheck5" className="me-2" />
                        <FormCheck.Label htmlFor="defaultCheck5" className="mb-0">Remember me</FormCheck.Label>
                      </Form.Check>
                      <Card.Link className="small text-end">Lost password?</Card.Link>
                    </div> */}
                  </Form.Group>
                  <Button
                    variant="primary"
                    type="submit"
                    className="w-100"
                    onClick={handleLogin}
                  >
                    Sign In
                  </Button>
                </Form>
                {loading ? (
                  <div className="loading_div">
                    <Loader
                      type="Puff"
                      color="#00BFFF"
                      height={75}
                      width={75}
                    />
                  </div>
                ) : (
                  <></>
                )}
                <div
                  className="mt-3 mb-4 text-center reset_password"
                  onClick={resetPassword}
                >
                  <span>Forgot or Reset password</span>
                </div>
                {/* <div className="mt-3 mb-4 text-center">
                  <span className="fw-normal">or login with</span>
                </div>
                <div className="d-flex justify-content-center my-4">
                  <Button variant="outline-light" className="btn-icon-only btn-pill text-facebook me-2">
                    <FontAwesomeIcon icon={faFacebookF} />
                  </Button>
                  <Button variant="outline-light" className="btn-icon-only btn-pill text-twitter me-2">
                    <FontAwesomeIcon icon={faTwitter} />
                  </Button>
                  <Button variant="outline-light" className="btn-icon-only btn-pil text-dark">
                    <FontAwesomeIcon icon={faGithub} />
                  </Button>
                </div>
                <div className="d-flex justify-content-center align-items-center mt-4">
                  <span className="fw-normal">
                    Not registered?
                    <Card.Link as={Link} to={Routes.Signup.path} className="fw-bold">
                      {` Create account `}
                    </Card.Link>
                  </span>
                </div> */}
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  );
};
