import React, { useEffect, useState, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBackward,
  faCheck,
  faCog,
  faHome,
  faPlus,
  faSave,
  faSearch,
  faSlidersH,
} from "@fortawesome/free-solid-svg-icons";
import {
  Col,
  Row,
  Form,
  Button,
  ButtonGroup,
  Breadcrumb,
  InputGroup,
  Dropdown,
  Card,
  Image,
} from "@themesberg/react-bootstrap";

import CustomersTable from "../components/CustomersTable";
import Modal from "react-modal";
import "../scss/style.css";
import { db } from "../firebase";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";

function Customers({ history }) {
  const [isOpen, setIsOpen] = useState(false);
  function toggleModal() {
    setIsOpen(true);
  }
  function toggleModalFalse() {
    setIsOpen(false);
    setIsEditCustomer(false);
    setEditCustomerObj({});
  }
  function toggleEditModal(ob) {
    setIsOpen(true);
    setIsEditCustomer(true);
    setEditCustomerObj(ob);
  }
  const [refreshData, setRefreshData] = useState(false);
  const [recordsPerPage, setRecordsPerPage] = useState(10);
  const dropdown = [
    { name: 10, checked: true },
    { name: 20, checked: false },
    { name: 30, checked: false },
  ];
  const [startAssetRecord, setStartAssetRecord] = useState(1);
  const [endAssetRecord, setEndAssetRecord] = useState(recordsPerPage);
  const [paginationElements, setPaginationItems] = useState([]);
  const [dropdownItems, setDropDownItems] = useState(dropdown);
  const [disablePrev, setDisablePrev] = useState(false);
  const [disableNext, setDisableNext] = useState(false);
  const [currentAssetPage, setCurrentAssetPage] = useState(0);
  const [transFilterString, setTransFilterString] = useState("");
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const [filteredCustomers, setFilteredCustomers] = useState([]);
  const [isEditCustomer, setIsEditCustomer] = useState(false);
  const [editCustomerObj, setEditCustomerObj] = useState({});

  useEffect(() => {
    loadCustomers();
  }, [refreshData]);
  const loadCustomers = async () => {
    const data = await db.collection("customers").orderBy("name").get();
    let userArr = [];
    for (var i = 0; i < data.docs.length; i++) {
      var custObj = data.docs[i].data();
      var docId = data.docs[i].id;
      const customer = {
        id: docId,
        name: custObj.name,
        phone: custObj.phone,
        email: custObj.email,
        street_address: custObj.street_address,
        city: custObj.city,
        state: custObj.state,
        country: custObj.country,
        zipcode: custObj.zipcode,
      };
      userArr.push(customer);
    }
    setUsers(userArr);
    setFilteredCustomers(userArr);
    let num = Math.ceil(userArr.length / recordsPerPage);
    let pagiArr = [];
    for (let i = 1; i <= num; i++) {
      let item = { value: i, active: i === 1 ? true : false };
      pagiArr.push(item);
    }
    setCurrentAssetPage(1);
    setDisablePrev(true);
    if (pagiArr.length > 1) {
      setDisableNext(false);
    } else {
      setDisableNext(true);
    }
    setPaginationItems(pagiArr);
    setLoading(false);
  };

  const cName = useRef("");
  const cPhone = useRef("");
  const cEmail = useRef("");
  const cAddress = useRef("");
  const cCity = useRef("");
  const cState = useRef("");
  const cZipcode = useRef("");

  function handlePageChange(e, index) {
    setCurrentAssetPage(e.value);
    setStartAssetRecord((e.value - 1) * recordsPerPage + 1);
    setEndAssetRecord(e.value * recordsPerPage);
    let arr = [...paginationElements];
    arr.forEach((item) => {
      if (item.value === e.value) {
        item.active = true;
      } else {
        item.active = false;
      }
    });
    setPaginationItems(arr);
    if (e.value === arr.length) {
      setDisableNext(true);
    } else {
      setDisableNext(false);
    }
    if (e.value === 1) {
      setDisablePrev(true);
    } else {
      setDisablePrev(false);
    }
  }
  const handlePrev = () => {
    setCurrentAssetPage(currentAssetPage - 1);
    setStartAssetRecord((currentAssetPage - 1 - 1) * recordsPerPage + 1);
    setEndAssetRecord((currentAssetPage - 1) * recordsPerPage);
    let arr = [...paginationElements];
    arr.forEach((item) => {
      if (item.value === currentAssetPage - 1) {
        item.active = true;
      } else {
        item.active = false;
      }
    });
    setPaginationItems(arr);
    if (currentAssetPage - 1 === arr.length) {
      setDisableNext(true);
    } else {
      setDisableNext(false);
    }
    if (currentAssetPage - 1 === 1) {
      setDisablePrev(true);
    } else {
      setDisablePrev(false);
    }
  };
  const handleNext = () => {
    setCurrentAssetPage(currentAssetPage + 1);
    setStartAssetRecord((currentAssetPage + 1 - 1) * recordsPerPage + 1);
    setEndAssetRecord((currentAssetPage + 1) * recordsPerPage);
    let arr = [...paginationElements];
    arr.forEach((item) => {
      if (item.value === currentAssetPage + 1) {
        item.active = true;
      } else {
        item.active = false;
      }
    });
    setPaginationItems(arr);
    if (currentAssetPage + 1 === arr.length) {
      setDisableNext(true);
    } else {
      setDisableNext(false);
    }
    if (currentAssetPage + 1 === 1) {
      setDisablePrev(true);
    } else {
      setDisablePrev(false);
    }
  };
  const handleSelectRecordsPerPage = (item) => {
    let transactionsArr = [...filteredCustomers];
    let num = Math.ceil(transactionsArr.length / item.name);
    let pagiArr = [];
    for (let i = 1; i <= num; i++) {
      let item = { value: i, active: i === 1 ? true : false };
      pagiArr.push(item);
    }
    setCurrentAssetPage(1);
    setDisablePrev(true);
    if (pagiArr.length > 1) {
      setDisableNext(false);
    } else {
      setDisableNext(true);
    }
    setPaginationItems(pagiArr);
    setRecordsPerPage(item.name);
    setStartAssetRecord(1);
    setEndAssetRecord(item.name);
    let arr = [...dropdown];
    let paginArr = [];
    arr.map((drop, i) => {
      let a = {
        name: drop.name,
        checked: drop.name === item.name ? true : false,
      };
      paginArr.push(a);
    });
    setDropDownItems(paginArr);
  };
  function onTransFilterStringChange(e) {
    setLoading(true);
    setTransFilterString(e.target.value);

    var tempTransactions = [];
    var keyword = e.target.value.toUpperCase();
    var index = 0;
    users.map((transaction) => {
      if (
        transaction.name.toUpperCase().includes(keyword) ||
        transaction.phone.toUpperCase().includes(keyword) ||
        transaction.email.toUpperCase().includes(keyword) ||
        transaction.street_address.toUpperCase().includes(keyword)
      ) {
        tempTransactions[index] = transaction;
        index = index + 1;
      }
    });
    setFilteredCustomers(tempTransactions);
    setLoading(false);
  }
  const SwalWithBootstrapButtons = withReactContent(
    Swal.mixin({
      customClass: {
        confirmButton: "btn btn-primary me-3",
        cancelButton: "btn btn-gray",
      },
      buttonsStyling: true,
    })
  );
  const showConfirmationDialog = async (message) => {
    const result = await SwalWithBootstrapButtons.fire({
      icon: "info",
      title: message,
      text: "",
      showCancelButton: false,
      okayButtonText: "Okay",
    });

    if (result.isConfirmed) {
      setRefreshData(!refreshData);
    }
  };
  async function onTransactionDelete(id) {
    if (id !== undefined) {
      console.log("onTransactionDelete: " + id);
      const result = await SwalWithBootstrapButtons.fire({
        icon: "error",
        title: "Confirm deletion!",
        text: "Are you sure you want to delete this Customer?",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel!",
      });

      if (result.isConfirmed) {
        try {
          await db
            .collection("customers")
            .doc(id)
            .delete()
            .then(() => {
              showConfirmationDialog(
                "Customer deleted successfully from the list."
              );
            })
            .catch((error) => {
              console.log(error);
              SwalWithBootstrapButtons.fire(
                "Not deleted!",
                "Unable to delete the transaction",
                "warning"
              );
            });
        } catch (err) {
          console.log(err);
          await SwalWithBootstrapButtons.fire(
            "Failed!",
            "Unable to delete the transaction",
            "warning"
          );
        }
      }
    }
  }

  const saveNewCustomer = async () => {
    const customer = {
      name: cName.current.value,
      phone: cPhone.current.value,
      email: cEmail.current.value,
      street_address: cAddress.current.value,
      city: cCity.current.value,
      zipcode: cZipcode.current.value,
      state: cState.current.value,
      country: "United States",
    };
    try {
      await db.collection("customers").add(customer);
      toggleModalFalse();
      alert("Customer saved successfully.");
      history.replace("/customers");
      setRefreshData(!refreshData);
    } catch (err) {
      console.error(err);
      alert(err.message);
    }
  };
  const updateCustomer = async () => {
    const customer = {
      name: cName.current.value,
      phone: cPhone.current.value,
      email: cEmail.current.value,
      street_address: cAddress.current.value,
      city: cCity.current.value,
      zipcode: cZipcode.current.value,
      state: cState.current.value,
      country: "United States",
    };
    try {
      await db.collection("customers").doc(editCustomerObj.id).update(customer);
      toggleModalFalse();
      alert("Customer updated successfully.");
      history.replace("/customers");
      setRefreshData(!refreshData);
    } catch (err) {
      console.error(err);
      alert(err.message);
    }
  };

  return (
    <>
      <div className="d-lg-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="mb-4 mb-lg-0">
          <h4>Customers</h4>
          <p className="mb-0">List of all customers</p>
        </div>
        <div className="btn-toolbar mb-2 mb-md-0">
          <Button variant="primary" size="sm" onClick={toggleModal}>
            <FontAwesomeIcon icon={faPlus} className="me-2" /> Add New Customer
          </Button>

          {/* START: Add New Transaction Modal */}
          <Modal
            isOpen={isOpen}
            onRequestClose={toggleModalFalse}
            contentLabel="Add New Transaction"
            className="mymodal"
            overlayClassName="myoverlay"
            closeTimeoutMS={10}
          >
            <Card border="light" className="bg-white shadow-sm mb-4">
              <Card.Body>
                <h5 className="mb-4">
                  {isEditCustomer ? "Update Customer" : "New Customer"}
                </h5>
                <Form>
                  <Row>
                    <Col md={12} className="mb-3">
                      <Form.Group id="name">
                        <Form.Label>Name</Form.Label>
                        <Form.Control
                          required
                          type="text"
                          placeholder="Full name"
                          defaultValue={editCustomerObj?.name}
                          ref={cName}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6} className="mb-3">
                      <Form.Group id="phone">
                        <Form.Label>Phone</Form.Label>
                        <Form.Control
                          required
                          type="text"
                          placeholder="Contact number"
                          defaultValue={editCustomerObj?.phone}
                          ref={cPhone}
                        />
                      </Form.Group>
                    </Col>
                    <Col md={6} className="mb-3">
                      <Form.Group id="email">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                          required
                          type="email"
                          defaultValue={editCustomerObj?.email}
                          placeholder="Primary email"
                          ref={cEmail}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12} className="mb-3">
                      <Form.Group id="address">
                        <Form.Label>Address</Form.Label>
                        <Form.Control
                          required
                          type="text"
                          placeholder="Street address"
                          defaultValue={editCustomerObj?.street_address}
                          ref={cAddress}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6} className="mb-3">
                      <Form.Group id="city">
                        <Form.Label>City</Form.Label>
                        <Form.Control
                          required
                          type="text"
                          placeholder="City"
                          defaultValue={editCustomerObj?.city}
                          ref={cCity}
                        />
                      </Form.Group>
                    </Col>
                    <Col md={6} className="mb-3">
                      <Form.Group id="state">
                        <Form.Label>State</Form.Label>
                        <Form.Control
                          required
                          type="text"
                          defaultValue={editCustomerObj?.state}
                          placeholder="State"
                          ref={cState}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6} className="mb-3">
                      <Form.Group id="zipcode">
                        <Form.Label>Zipcode</Form.Label>
                        <Form.Control
                          required
                          type="text"
                          defaultValue={editCustomerObj?.zipcode}
                          placeholder="Zipcode"
                          ref={cZipcode}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <div className="post-meta">
                    <Button
                      variant="link"
                      className="me-3 text-primary"
                      onClick={toggleModalFalse}
                    >
                      <FontAwesomeIcon icon={faBackward} className="me-2" />{" "}
                      Cancel
                    </Button>
                    <Button
                      variant="secondary"
                      onClick={
                        isEditCustomer ? updateCustomer : saveNewCustomer
                      }
                    >
                      <FontAwesomeIcon icon={faSave} className="me-2" />{" "}
                      {isEditCustomer ? "Update" : "Save"}
                    </Button>
                  </div>
                </Form>
              </Card.Body>
            </Card>
            {/* <button onClick={toggleModal}>Close modal</button> */}
          </Modal>
          {/* END: Add New Transaction Modal */}
        </div>
      </div>

      <div className="table-settings mb-4">
        <Row className="justify-content-between align-items-center">
          <Col xs={9} lg={4} className="d-flex">
            <InputGroup>
              <InputGroup.Text>
                <FontAwesomeIcon icon={faSearch} />
              </InputGroup.Text>
              <Form.Control
                type="text"
                placeholder="Search"
                onKeyUp={onTransFilterStringChange}
              />
            </InputGroup>
          </Col>
          <Col xs={3} lg={8} className="text-end">
            <Dropdown as={ButtonGroup}>
              <Dropdown.Toggle
                split
                as={Button}
                variant="link"
                className="text-dark m-0 p-0"
              >
                <span className="icon icon-sm icon-gray">
                  <FontAwesomeIcon icon={faCog} />
                </span>
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-menu-xs dropdown-menu-right">
                <Dropdown.Item className="fw-bold text-dark">
                  Show
                </Dropdown.Item>
                {dropdownItems.map((val) => {
                  if (val.checked) {
                    return (
                      <Dropdown.Item
                        className="d-flex fw-bold"
                        onClick={() => handleSelectRecordsPerPage(val)}
                      >
                        {val.name}{" "}
                        <span className="icon icon-small ms-auto">
                          <FontAwesomeIcon icon={faCheck} />
                        </span>
                      </Dropdown.Item>
                    );
                  } else {
                    return (
                      <Dropdown.Item
                        className="fw-bold"
                        onClick={() => handleSelectRecordsPerPage(val)}
                      >
                        {val.name}
                      </Dropdown.Item>
                    );
                  }
                })}
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        </Row>
      </div>

      <CustomersTable
        refreshData={refreshData}
        loading={loading}
        setLoading={setLoading}
        filteredUsers={filteredCustomers}
        disablePrev={disablePrev}
        disableNext={disableNext}
        handleNext={handleNext}
        handlePrev={handlePrev}
        paginationElements={paginationElements}
        handlePageChange={handlePageChange}
        startAssetRecord={startAssetRecord}
        endAssetRecord={endAssetRecord}
        customers={users}
        onTransactionDelete={onTransactionDelete}
        toggleEditModal={toggleEditModal}
      />
    </>
  );
}

export default Customers;
