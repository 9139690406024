import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import {
  Card,
  Image,
  Table,
  Pagination,
  Tooltip,
  OverlayTrigger,
} from "@themesberg/react-bootstrap";

import Loading from "../pages/components/Loading";

function CustomersTable(props) {
  const {
    refreshData,
    loading,
    setLoading,
    filteredUsers,
    disablePrev,
    disableNext,
    handleNext,
    handlePrev,
    paginationElements,
    handlePageChange,
    startAssetRecord,
    endAssetRecord,
    customers,
    onTransactionDelete,
    toggleEditModal
  } = props;
  const [selectedUsers, setSelectedUsers] = useState([]);
  const totalUsers = filteredUsers.length;
  const allSelected = selectedUsers.length === totalUsers;

  const selectAllUsers = () => {
    const newSelected =
      selectedUsers.length === totalUsers ? [] : filteredUsers.map((u) => u.id);
    setSelectedUsers(newSelected);
  };

  const selectUser = (id) => {
    if (selectedUsers.indexOf(id) !== -1) {
      setSelectedUsers(selectedUsers.filter((uid) => uid !== id));
    } else {
      setSelectedUsers([...selectedUsers, id]);
    }
  };

  const TableRow = (props) => {
    const {
      id,
      image,
      name,
      email,
      phone,
      street_address,
      city,
      state,
      zipcode,
    } = props;
    const isSelected = selectedUsers.indexOf(id) !== -1;
    const nameFirstLetters = name.match(/\b\w/g).join("");

    return (
      <tr key={id}>
        <td>
          <Card.Link className="d-flex align-items-center">
            {image ? (
              <Image src={image} className="user-avatar rounded-circle me-3" />
            ) : (
              <div className="user-avatar bg-tertiary me-3">
                <span>{nameFirstLetters}</span>
              </div>
            )}
          </Card.Link>
        </td>
        <td>
          <span className="fw-normal">{name}</span>
        </td>
        <td>
          <span className="fw-normal">{phone}</span>
        </td>
        <td>
          <span className="fw-normal">{email}</span>
        </td>
        <td>
          <span className="fw-normal">{street_address}</span>
        </td>
        <td>
          <span className="fw-normal">
            {city}, {state} {zipcode}
          </span>
        </td>
        <td>
          <OverlayTrigger
            trigger={["hover", "focus"]}
            placement="top"
            overlay={<Tooltip>Edit</Tooltip>}
          >
            <Card.Link className="text-primary ms-2">
              <FontAwesomeIcon icon={faEdit} onClick={()=>{toggleEditModal(props)}} />
            </Card.Link>
          </OverlayTrigger>

          <OverlayTrigger
            trigger={["hover", "focus"]}
            placement="top"
            overlay={<Tooltip>Delete</Tooltip>}
          >
            <Card.Link className="text-danger ms-2">
              <FontAwesomeIcon
                icon={faTimesCircle}
                onClick={() => {
                  onTransactionDelete(id);
                }}
              />
            </Card.Link>
          </OverlayTrigger>
        </td>
      </tr>
    );
  };

  return (
    <Card border="light" className="table-wrapper table-responsive shadow-sm">
      <Card.Body>
        {loading ? (
          <Loading />
        ) : (
          <>
            <Table hover className="user-table align-items-center">
              <thead>
                <tr>
                  <th className="border-bottom"></th>
                  <th className="border-bottom">Name</th>
                  <th className="border-bottom">Phone</th>
                  <th className="border-bottom">Email</th>
                  <th className="border-bottom">Address</th>
                  <th className="border-bottom">City/State</th>
                  <th className="border-bottom">Action</th>
                </tr>
              </thead>
              <tbody>
                {filteredUsers
                  .slice(startAssetRecord - 1, endAssetRecord)
                  .map((u) => (
                    <TableRow key={`user-${u.id}`} {...u} />
                  ))}
              </tbody>
            </Table>
            <Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between">
              <Pagination className="mb-2 mb-lg-0" style={{ zIndex: 0 }}>
                <Pagination.Prev disabled={disablePrev} onClick={handlePrev}>
                  Previous
                </Pagination.Prev>
                {paginationElements.length > 0 &&
                  paginationElements.map((item, index) => {
                    return (
                      <>
                        <Pagination.Item
                          value={item.value}
                          key={item.value}
                          onClick={(e) => handlePageChange(item, index)}
                          active={item.active}
                        >
                          {item.value}
                        </Pagination.Item>
                      </>
                    );
                  })}
                <Pagination.Next disabled={disableNext} onClick={handleNext}>
                  Next
                </Pagination.Next>
              </Pagination>
              {filteredUsers.length > 0 && (
                <small className="fw-bold">
                  Showing <b>{startAssetRecord}</b>
                  {" - "}
                  {endAssetRecord >= customers.length && (
                    <b>{customers.length}</b>
                  )}
                  {endAssetRecord < customers.length && <b>{endAssetRecord}</b>}{" "}
                  of <b>{customers.length}</b>
                </small>
              )}
            </Card.Footer>
          </>
        )}
      </Card.Body>
    </Card>
  );
}

export default CustomersTable;
