

export default [
    {
        "id": 1,
        "title": "Meeting with Ms. Bonnie from Themesberg LLC",
        "time": "10:00 AM",
        "statusKey": "done",
        "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi pulvinar feugiat consequat. Duis lacus nibh, sagittis id varius vel, aliquet non augue."
    },
    {
        "id": 2,
        "title": "Meeting with Ms. Bonnie from Themesberg LLC",
        "time": "10:00 AM",
        "statusKey": "inProgress",
        "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi pulvinar feugiat consequat. Duis lacus nibh, sagittis id varius vel, aliquet non augue."
    },
    {
        "id": 3,
        "title": "Meeting with Ms. Bonnie from Themesberg LLC",
        "time": "10:00 AM",
        "statusKey": "inProgress",
        "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi pulvinar feugiat consequat. Duis lacus nibh, sagittis id varius vel, aliquet non augue."
    },
    {
        "id": 4,
        "title": "Meeting with Ms. Bonnie from Themesberg LLC",
        "time": "10:00 AM",
        "statusKey": "inProgress",
        "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi pulvinar feugiat consequat. Duis lacus nibh, sagittis id varius vel, aliquet non augue."
    },
    {
        "id": 5,
        "title": "Meeting with Ms. Bonnie from Themesberg LLC",
        "time": "10:00 AM",
        "statusKey": "waiting",
        "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi pulvinar feugiat consequat. Duis lacus nibh, sagittis id varius vel, aliquet non augue."
    },
    {
        "id": 6,
        "title": "Meeting with Ms. Bonnie from Themesberg LLC",
        "time": "10:00 AM",
        "statusKey": "waiting",
        "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi pulvinar feugiat consequat. Duis lacus nibh, sagittis id varius vel, aliquet non augue."
    },
    {
        "id": 7,
        "title": "Meeting with Ms. Bonnie from Themesberg LLC",
        "time": "10:00 AM",
        "statusKey": "done",
        "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi pulvinar feugiat consequat. Duis lacus nibh, sagittis id varius vel, aliquet non augue."
    }
]