
import React, { useState } from "react";
import SimpleBar from 'simplebar-react';
import { useLocation } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { useHistory } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faBook, faBoxOpen, faCalendar, faCaravan, faChartPie, faClipboardList, faCog, faCreditCard, faDollarSign, faFile, faFileAlt, faHandHoldingUsd, faInbox, faMapMarkedAlt, faMoneyCheck, faPlane, faSignOutAlt, faTable, faThLarge, faTicketAlt, faTimes, faUserCheck, faUsers, faChartBar } from "@fortawesome/free-solid-svg-icons";
import { faCalendarAlt } from '@fortawesome/free-regular-svg-icons';
import { Nav, Badge, Image, Button, Dropdown, Accordion, Navbar } from '@themesberg/react-bootstrap';
import { Link } from 'react-router-dom';

import { Routes } from "../routes";
import ProfilePicture from "../assets/img/sasprinter-icon.jpg";
import SASprinterIcon from "../assets/img/sasprinter-icon.jpg";
import { useCookies } from "react-cookie";
import { logout } from "../firebase";

export default (props = {}) => {
  const location = useLocation();
  const history = useHistory();
  const { pathname } = location;
  const [show, setShow] = useState(false);
  const contracted = props.contracted ? "contracted" : "";
  const showClass = show ? "show" : "";

  const [userProfile, setUserProfile] = useCookies(['name']);

  const onCollapse = () => setShow(!show);
  const onMouseEnter = () => props.onMouseEnter && props.onMouseEnter();
  const onMouseLeave = () => props.onMouseLeave && props.onMouseLeave();

  const events = isMobile ? {} : { onMouseEnter, onMouseLeave };

  const logoutUser = () => {
    console.log("logoutUser");
    setUserProfile("name", "null");
    logout();
    console.log("User successfully logged out");
    history.replace("/dashboard");
  };

  const CollapsableNavItem = (props) => {
    const { eventKey, title, icon, children = null } = props;
    const defaultKey = pathname.indexOf(eventKey) !== -1 ? eventKey : "";

    return (
      <Accordion as={Nav.Item} defaultActiveKey={defaultKey}>
        <Accordion.Item eventKey={eventKey}>
          <Accordion.Button as={Nav.Link} className="d-flex justify-content-between align-items-center">
            <span>
              <span className="sidebar-icon"><FontAwesomeIcon icon={icon} /> </span>
              <span className="sidebar-text">{title}</span>
            </span>
          </Accordion.Button>
          <Accordion.Body className="multi-level">
            <Nav className="flex-column">
              {children}
            </Nav>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    );
  };

  const NavItem = (props) => {
    const { title, link, target, icon, image, badgeText, badgeBg, badgeColor = "white" } = props;
    const classNames = badgeText ? "d-flex justify-content-between align-items-center" : "";
    const navItemClassName = link === pathname ? "active" : "";

    return (
      <Nav.Item className={navItemClassName} onClick={() => setShow(false)}>
        <Nav.Link as={Link} to={link} target={target} className={classNames}>
          <span>
            {icon ? <span className="sidebar-icon"><FontAwesomeIcon icon={icon} /> </span> : null}
            {image ? <Image src={image} width={20} height={20} className="sidebar-icon svg-icon" /> : null}

            {!show && contracted && !icon && !image ? (
              <span className="sidebar-text-contracted">{title[0]}</span>
            ) : null}

            <span className="sidebar-text">{title}</span>
          </span>
          {badgeText ? <Badge pill bg={badgeBg} text={badgeColor} className="badge-md notification-count">{badgeText}</Badge> : null}
        </Nav.Link>
      </Nav.Item>
    );
  };

  return (
    <>
      <Navbar expand={false} userName="Shan Justus" collapseOnSelect variant="dark" className="navbar-theme-primary px-4 d-md-none">
        <Navbar.Brand as={Link} className="me-lg-5" to={Routes.Dashboard.path}>
          <Image src={SASprinterIcon} className="navbar-brand-light" />
        </Navbar.Brand>
        <Navbar.Toggle as={Button} aria-controls="main-navbar" onClick={onCollapse}>
          <span className="navbar-toggler-icon" />
        </Navbar.Toggle>
      </Navbar>
      <CSSTransition timeout={300} in={show} classNames="sidebar-transition">
        <SimpleBar {...events} className={`${contracted} collapse ${showClass} sidebar d-md-block bg-primary text-white`}>
          <div className="sidebar-inner px-4 py-3">
            <div className="user-card d-flex d-md-none align-items-center justify-content-between justify-content-md-center pb-4">
              <div className="d-flex align-items-center">
                <div className="user-avatar lg-avatar me-4">
                  <Image src={ProfilePicture} className="card-img-top rounded-circle border-white" />
                </div>
                <div className="d-block">
                  <h6>Hi, {userProfile.name}</h6>
                  <Button as={Link} variant="secondary" size="xs" className="text-dark" onClick={() => logoutUser()}>
                    <FontAwesomeIcon icon={faSignOutAlt} className="me-2" /> Sign Out
                  </Button>
                </div>
              </div>
              <Nav.Link className="collapse-close d-md-none" onClick={onCollapse}>
                <FontAwesomeIcon icon={faTimes} />
              </Nav.Link>
            </div>
            <Nav className="flex-column pt-3 pt-md-0">
              <NavItem title="Sprinter" link={Routes.Presentation.path} image={SASprinterIcon} />

              {/* <CollapsableNavItem eventKey="dashboard/" title="Dashboard" icon={faChartPie}>
                <NavItem title="Overview" link={Routes.DashboardOverview.path} />
                <NavItem title="All Traffic" link={Routes.DashboardTraffic.path} />
                <NavItem title="Product Analysis" link={Routes.DashboardProductAnalysis.path} />
              </CollapsableNavItem> */}
              <NavItem title="Dashboard" icon={faChartPie} link={Routes.Dashboard.path} />
              <NavItem title="Customers" icon={faUsers} link={Routes.Customers.path} />
              <NavItem title="Bookings" icon={faCalendar} link={Routes.Bookings.path} />

              <Dropdown.Divider className="my-3 border-indigo" />

              
              <CollapsableNavItem eventKey="dashboard/" title="Rates" icon={faTicketAlt}>
                <NavItem title="Trip Rates" icon={faCaravan} link={Routes.TripRates.path} />
                <NavItem title="Airport Rates" icon={faPlane} link={Routes.AirportRates.path} />
              </CollapsableNavItem>

              <CollapsableNavItem eventKey="dashboard/" title="Accounting" icon={faDollarSign}>
                {/* <NavItem title="Payments" icon={faCreditCard} link={Routes.Transactions.path} /> */}
                <NavItem title="Transfers" icon={faBars} link={Routes.Transfers.path} />
                <NavItem title="Transactions" icon={faBars} link={Routes.Transactions.path} />
                <NavItem title="Account Summary" icon={faMoneyCheck} link={Routes.AccountSummary.path} />
                <NavItem title="Reports" icon={faChartBar} link={Routes.Report.path}/>
              </CollapsableNavItem>
            </Nav>
          </div>
        </SimpleBar>
      </CSSTransition>
    </>
  );
};
