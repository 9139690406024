
import React, { useEffect, useState } from "react";
import moment from "moment-timezone";
import Datetime from "react-datetime";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import { Col, Row, Form, Modal, Button, InputGroup } from '@themesberg/react-bootstrap';
import { trim } from "jquery";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import tripTypes from "../data/tripTypes";
import { db } from "../firebase";

export const BookingModal = (props) => {

  const [docId, setDocumentId] = useState(null);
  const [title, setTitle] = useState(props.title);
  const [start, setStart] = useState(props.start);
  const [end, setEnd] = useState(props.end);
  const [startMiles, setStartMiles] = useState(props.startMiles);
  const [endMiles, setEndMiles] = useState(props.endMiles);
  const [pickupTime, setPickupTime] = useState(props.pickupTime);
  const [dropoffTime, setDropoffTime] = useState(props.dropoffTime);
  const [tripCost, setTripCost] = useState(props.tripCost);
  const [settlementAmount, setSettlementAmount] = useState(props.settlementAmount);
  const [notes, setNotes] = useState(props.notes);
  const [tripType, setTripType] = useState(props.tripType);

  const [endDateVisible, setEndDateVisible] = useState(false);

  const { show = false, edit = false, id } = props;
  const startDate = start ? moment(start).format("YYYY-MM-DD") : moment().format("YYYY-MM-DD");
  const endDate = end ? moment(end).endOf("day").format("YYYY-MM-DD") : moment().format("YYYY-MM-DD");

  const onTitleChange = (e) => {
    setTitle(e.target.value);
    if(e.target.value === null || trim(e.target.value) === "")
      setTitleClass("mandatoryField");
    else
      setTitleClass("requiredField");
  };
  const onStartMilesChange = (e) => setStartMiles(e.target.value);
  const onEndMilesChange = (e) => setEndMiles(e.target.value);
  const onStartTimeChange = (e) => {
    setPickupTime(e.target.value);
    if(e.target.value === "0" || trim(e.target.value) === "")
      setPickupClass("mandatoryField");
    else
      setPickupClass("requiredField");
  }
  const onEndTimeChange = (e) => {
    setDropoffTime(e.target.value);
    if(e.target.value === "0" || trim(e.target.value) === "")
      setDropoffClass("mandatoryField");
    else
      setDropoffClass("requiredField");
  }
  const onTripCostChange = (e) => {
    setTripCost(e.target.value);
    if(trim(e.target.value) === "")
      setCostClass("mandatoryField");
    else
      setCostClass("requiredField");
  }
  const onSettlementAmountChange = (e) => setSettlementAmount(e.target.value);
  const onNotesChange = (e) => setNotes(e.target.value);
  const onTripTypeChange = (e) => setTripType(e.target.value);

  const [titleClass, setTitleClass] = useState("requiredField");
  const [startClass, setStartClass] = useState("requiredField");
  const [endClass, setEndClass] = useState("requiredField");
  const [costClass, setCostClass] = useState("requiredField");
  const [pickupClass, setPickupClass] = useState("requiredField");
  const [dropoffClass, setDropoffClass] = useState("requiredField");

  function toggleEndDateVisibility () {
    console.log("toggleEndDateVisibility " + endDateVisible + " => " + !endDateVisible);
    setEnd(props.end);
    setEndDateVisible(!endDateVisible);
  }

  useEffect(() => {
    const loadBookingDetails = async () => {
      console.log("Load booking details " + props.id);
      //console.log("Booking id: " + props.id);
      const bookingQuery = await db.collection("bookings").where("id", "==", Number(props.id)).get();
      //console.log("Booking found: " + bookingQuery.docs.length);
      if (bookingQuery.docs.length !== 0) {
        const booking = bookingQuery.docs[0].data();
        setDocumentId(bookingQuery.docs[0].id);
        setStartMiles(booking.startMiles);
        setEndMiles(booking.endMiles);
        setPickupTime(booking.pickupTime);
        setDropoffTime(booking.dropoffTime);
        setTripCost(booking.tripCost);
        setSettlementAmount(booking.settlementAmount);
        setNotes(booking.notes);
        setTripType(booking.tripType);
        //setStart(moment(booking.start.toDate().toLocaleString()).format("YYYY-MM-DD"));
        //setEnd(moment(booking.end.toDate().toLocaleString()).format("YYYY-MM-DD"));
        //setEnd(start);
        if(end === null)
          setEnd(start);
        //console.log("Booking => " + title + " => " + start + " => " + end);
      }
    };
    loadBookingDetails();
  }, []);

  const SwalWithBootstrapButtons = withReactContent(Swal.mixin({
    customClass: {
      okayButton: 'btn btn-primary me-3',
    },
    buttonsStyling: true
  }));

  const showConfirmationDialog = async (message) => {
    const result = await SwalWithBootstrapButtons.fire({
      icon: 'warning',
      title: message,
      text: '',
      showCancelButton: false,
      okayButtonText: "Okay"
    });
  };

  const onConfirm = () => {
    const finalStart = moment(startDate).toDate();
    // const finalEnd = moment(endDate).add(1, "days").toDate();
    const finalEnd = moment(endDate).endOf('day').toDate();
    if(title === null || trim(title) === "")
      setTitleClass("mandatoryField");

    if(startDate === null || trim(startDate) === "")
      setStartClass("mandatoryField");

    if(endDate === null || trim(endDate) === "")
      setEndClass("mandatoryField");

    if(pickupTime === "0" || trim(pickupTime) === "")
      setPickupClass("mandatoryField");

    if(dropoffTime === "0" || trim(dropoffTime) === "")
      setDropoffClass("mandatoryField");

    if(trim(tripCost) === "")
      setCostClass("mandatoryField");

    if(title === null || trim(title) === "" 
      || pickupTime === "0" || trim(pickupTime) === ""
      || dropoffTime === "0" || trim(dropoffTime) === ""
      || trim(tripCost) === "") {
        showConfirmationDialog("Please check all mandatory fields");
    } else {

      if(typeof tripCost === 'undefined') {
        console.log("tripCost is undefined");
        setTripCost(0);
      }

      console.log("startMiles => " + startMiles);
      console.log("endMiles => " + endMiles);
      console.log("tripCost => " + tripCost);
      console.log("settlementAmount => " + settlementAmount);
      console.log("notes => " + notes);
      
      if(startMiles === undefined || startMiles === null || trim(startMiles) === "")
        setStartMiles(null);

      if(endMiles === undefined || endMiles === null || trim(endMiles) === "")
        setEndMiles(null);
      
      if(typeof tripCost === 'undefined' || tripCost === null || trim(tripCost) === "")
        setTripCost(null);

      if(settlementAmount === undefined || settlementAmount === null || trim(settlementAmount) === "")
        setSettlementAmount(null);

      if(notes === undefined || notes === null || trim(notes) === "")
        setNotes(null);

      const payload = { id, title, start: finalStart, end: finalEnd, startMiles, endMiles, pickupTime, dropoffTime, tripCost, settlementAmount, notes, tripType };

      // console.log("payload: " + JSON.stringify(payload));
      console.log(payload)

      if (edit) {
        return props.onUpdate && props.onUpdate(docId, payload);
      }

      return props.onAdd && props.onAdd(payload);
    }
  }
  const onDelete = () => edit && props.onDelete && props.onDelete(id, docId);
  const onHide = () => props.onHide && props.onHide();

  return (
    <Modal as={Modal.Dialog} centered show={show} onHide={onHide}>
      <Form className="modal-content">
        <Modal.Body>
          <Form.Group id="title" className="mb-4">
            <Form.Label>Trip customer name</Form.Label>
            <Form.Control
              required
              autoFocus
              type="text"
              value={title}
              onChange={onTitleChange}
              className={titleClass} />
          </Form.Group>
          <Row>
            <Col xs={12} lg={6}>
              <Form.Group id="startDate">
                <Form.Label>Start date</Form.Label>
                <Datetime
                  timeFormat={false}
                  closeOnSelect={true}
                  onChange={setStart}
                  className={startClass}
                  value={moment(startDate).endOf('day').toDate()}
                  renderInput={(props, openCalendar) => (
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faCalendarAlt} />
                      </InputGroup.Text>
                      <Form.Control
                        required
                        type="text"
                        placeholder="YYYY-MM-DD"
                        value={startDate}
                        onFocus={openCalendar}
                        onChange={() => {}} />
                    </InputGroup>
                  )} />
              </Form.Group>
            </Col>
            <Col xs={12} lg={6}>
              <Form.Group id="endDate" className="mb-2">
                <Form.Label>End date</Form.Label>
                <Datetime
                  className={endClass}
                  timeFormat={false}
                  closeOnSelect={true}
                  onChange={setEnd}
                  visible={false}
                  value={moment(endDate).endOf('day').toDate()}
                  isValidDate={currDate => currDate.isAfter(moment(start).subtract(1, "days").toDate())}
                  renderInput={(props, openCalendar) => (
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faCalendarAlt} />
                      </InputGroup.Text>
                      <Form.Control
                        required
                        type="text"
                        placeholder="YYYY-MM-DD"
                        value={endDate}
                        onFocus={openCalendar}
                        onChange={() => {}} />
                    </InputGroup>
                  )} />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col xs={12} lg={6}>
              <Form.Group id="pickupTime" className="mb-4">
                <Form.Label>Pickup time</Form.Label>
                <Form.Control
                  type="text"
                  className={pickupClass}
                  value={pickupTime}
                  onChange={onStartTimeChange} />
              </Form.Group>
            </Col>
            <Col xs={12} lg={6}>
              <Form.Group id="dropoffTime" className="mb-4">
                <Form.Label>Dropoff time</Form.Label>
                <Form.Control
                  type="text"
                  className={dropoffClass}
                  value={dropoffTime}
                  onChange={onEndTimeChange} />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col xs={12} lg={6}>
              <Form.Group id="startMiles" className="mb-4">
                <Form.Label>Start miles</Form.Label>
                <Form.Control
                  type="number"
                  value={startMiles}
                  onChange={onStartMilesChange} />
              </Form.Group>
            </Col>
            <Col xs={12} lg={6}>
              <Form.Group id="endMiles" className="mb-4">
                <Form.Label>End miles</Form.Label>
                <Form.Control
                  type="number"
                  value={endMiles}
                  onChange={onEndMilesChange} />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col xs={12} lg={6}>
              <Form.Group id="tripCost" className="mb-4">
                <Form.Label>Enter trip cost</Form.Label>
                <Form.Control
                  type="number"
                  className={costClass}
                  value={tripCost}
                  onChange={onTripCostChange} />
              </Form.Group>
            </Col>
            <Col xs={12} lg={6}>
              <Form.Group id="settlementAmount" className="mb-4">
                <Form.Label>Enter final settlement amount</Form.Label>
                <Form.Control
                  type="number"
                  value={settlementAmount}
                  onChange={onSettlementAmountChange} />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col xs={12} lg={6}>
              <Form.Group id="tripType" className="mb-4">
                <Form.Label>Trip type</Form.Label>
                <Form.Select defaultValue={tripType} onChange={onTripTypeChange} >
                  {tripTypes.map(tripType => (
                    <option value={tripType.title}>{tripType.title}</option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>
            <Col xs={12} lg={6}>
              <Form.Group id="notes" className="mb-4">
                <Form.Label>Trip information</Form.Label>
                <Form.Control
                  type="text"
                  value={notes}
                  onChange={onNotesChange} />
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" className="me-2" onClick={onConfirm}>
            {edit ? "Update event" : "Add new event"}
          </Button>

          {edit ? (
            <Button variant="danger" onClick={onDelete}>
              Delete event
            </Button>
          ) : null}

          <Button variant="link" className="text-gray ms-auto" onClick={onHide}>
            Close
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};
