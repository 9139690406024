
export default [
    {
        "id": "z7EbDOU1txsy8dRds1hi",
        "name": "Cash",
        "sl":1,
    },
    {
        "id": "t90cjLO4FAKEdzAemEpC",
        "name": "Online",
        "sl":2,
    },
    {
        "id": "RufjArI0ggF4yrEIjYBu",
        "name": "PayPal",
        "sl":3,
    },
    {
        "id": "El3woyiuAn0h1opnNSX5",
        "name": "Bank Transfer",
        "sl":4,
    },
    {
        "id": "XYe0Y9TgMv0Aq35m58r2",
        "name": "Zelle",
        "sl":5,
    },
    {
        "id": "qIHNGAVOiBah2IyLBQBO",
        "name": "Credit Card",
        "sl":6,
    },
    {
        "id": "iwlxK4oCN7V3txaJK1Kn",
        "name": "Other",
        "sl":7,
    },
]