import React, { useState } from "react";
import { Card, Button } from '@themesberg/react-bootstrap';
import IncomeValueChart from "./IncomeValueChart";
import ExpenseValueChart from "./ExpenseValueChart";

export const AccountChart = (props) => {

    const { title, value, incomeClass, expenseClass } = props;
  
    return (
      <Card className="bg-secondary-alt shadow-sm">
        <Card.Header className="d-flex flex-row align-items-center flex-0">
          <div className="d-block">
            <h5 className="fw-normal mb-2">
              {title}
            </h5>
            <h3>${value}</h3>
          </div>
          {/* <div className="d-flex ms-auto">
            <Button variant="secondary" size="sm" className="me-2">Month</Button>
            <Button variant="primary" size="sm" className="me-3">Week</Button>
          </div> */}
        </Card.Header>

        <Card.Body className={incomeClass}>
          <IncomeValueChart />
        </Card.Body>

        <Card.Body className={expenseClass}>
          <ExpenseValueChart />
        </Card.Body>
        
      </Card>
    );
  };