
import React, { useEffect, useState } from "react";
import { Row, Col, Card, Table } from '@themesberg/react-bootstrap';
import { db } from '../firebase';

function TripRates() {

  const [tripRates, setTripRates] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const data = await db.collection("trip_rates").orderBy("trip_type").get();
      setTripRates(data.docs.map(doc => ({ ...doc.data(), id: doc.id })));
    };
    fetchData();
  }, []);

  return (
    <>
      <Row className="d-xl-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <Col className="d-block mb-4 mb-xl-0">
          <h4>Trip Rates</h4>
          <p className="mb-0">
            Rates defined for the leisure trips
          </p>
        </Col>
      </Row>

      <Card border="light" className="shadow-sm mb-4">
        <Card.Body className="pb-0">
          <Table responsive className="table-centered table-nowrap rounded mb-0">
            <thead className="thead-light">
              <tr>
                <th className="border-0">Trip Type</th>
                <th className="border-0">Rate Without Liability</th>
                <th className="border-0">Rate With Liability</th>
                <th className="border-0">Max Miles</th>
                <th className="border-0">Additional Charge Per Mile</th>
              </tr>
            </thead>
            <tbody>
              {tripRates.map(tripRate => (
                <tr key={tripRate.id}>
                  <td className="border-0">{tripRate.trip_type}</td>
                  <td className="border-0">${tripRate.rate_without_liability}</td>
                  <td className="border-0">${tripRate.rate_with_liability}</td>
                  <td className="border-0">{tripRate.max_miles} miles</td>
                  <td className="border-0">${tripRate.add_miles_charge}/miles</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    </>
  );
}

export default TripRates;