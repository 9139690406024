
import React, { useEffect, useState } from "react";
import { Row, Col, Card, Table } from '@themesberg/react-bootstrap';
import { db } from '../firebase';

function AirportRates() {

  const [airportRates, setAirportRates] = useState([]);

  useEffect(() => {
    console.log("useEffect");
    const fetchData = async () => {
      console.log("fetchData");
      const data = await db.collection("airport_rates").orderBy("airport_name").get();
      setAirportRates(data.docs.map(doc => ({ ...doc.data(), id: doc.id })));
    };
    fetchData();
  }, []);

  return (
    <>
      <Row className="d-xl-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <Col className="d-block mb-4 mb-xl-0">
          <h4>Aiport Rates</h4>
          <p className="mb-0">
            Rates defined for the airport shuttle service
          </p>
        </Col>
      </Row>

      <Card border="light" className="shadow-sm mb-4">
        <Card.Body className="pb-0">
          <Table responsive className="table-centered table-nowrap rounded mb-0">
            <thead className="thead-light">
              <tr>
                <th className="border-0">Airport</th>
                <th className="border-0">Rental Charge</th>
                <th className="border-0">Driver Charge</th>
                <th className="border-0">Total Charge</th>
                <th className="border-0">Max Miles</th>
                <th className="border-0">Additional Miles Charge</th>
              </tr>
            </thead>
            <tbody>
              {airportRates.map(airportRate => (
                <tr key={airportRate.id}>
                  <td className="border-0">{airportRate.airport_name}</td>
                  <td className="border-0">${airportRate.rental_charge}</td>
                  <td className="border-0">${airportRate.driver_charge}</td>
                  <td className="border-0">${airportRate.total_charge}</td>
                  <td className="border-0">{airportRate.max_miles} miles</td>
                  <td className="border-0">${airportRate.additional_miles_charge}/miles</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    </>
  );
}

export default AirportRates;