import React, { useEffect, useRef, useState } from "react";

import {
  Card,
  Image,
  Button,
  Container,
  Navbar,
} from "@themesberg/react-bootstrap";
import { HashLink } from "react-router-hash-link";

import SASprinterLogo from "../assets/img/app-sasprinter.png";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import bootstrapPlugin from "@fullcalendar/bootstrap";
import interactionPlugin from "@fullcalendar/interaction";
import moment from "moment-timezone";
import { db } from "../firebase";
import "../scss/volt.scss";

const AvailabiltyPage = () => {
  const calendarRef = useRef();
  const [events, setEvents] = useState([]);
  const currentDate = moment().format("YYYY-MM-DD");
  useEffect(() => {
    const loadEvents = async () => {
      console.log("Load bookings");
      try {
        const data = await db
          .collection("bookings")
          .orderBy("start", "desc")
          .get();
        const bookings = [];
        for (var i = 0; i < data.docs.length; i++) {
          var bookingObj = data.docs[i].data();
          const booking = {
            id: bookingObj.id,
            title: 'Not available',
            start: moment(bookingObj.start.toDate()).format("YYYY-MM-DD"),
            end: moment(bookingObj.end.toDate()).format("YYYY-MM-DD HH:mm"),
            pickupTime: bookingObj.pickupTime,
            dropoffTime: bookingObj.dropoffTime,
            tripCost: bookingObj.tripCost,
            settlementAmount: bookingObj.settlementAmount,
            tripType: bookingObj.tripType,
            className: bookingObj.className === "bg-blue text-white" ? "bg-red" : '',
          };
          //console.log(booking.title + " => " + booking.start + " => " + booking.end);
          bookings.push(booking);
        }
        console.log(bookings);
        setEvents(bookings);
        console.log("Total bookings: " + bookings.length);
        //console.log("Bookings: " + JSON.stringify(bookings));
      } catch (err) {
        console.log("Error while retrieving bookings: " + err);
      }
    };
    loadEvents();
  }, []);

  return (
    <>
      <Navbar
        variant="dark"
        expand="lg"
        bg="dark"
        className="navbar-transparent navbar-theme-primary sticky-top"
      >
        <Container className="position-relative justify-content-between px-3">
          <Navbar.Brand
            as={HashLink}
            to="#home"
            className="me-lg-3 d-flex align-items-center"
          >
            <Image src={SASprinterLogo} />
          </Navbar.Brand>

          <div className="d-flex align-items-center">
            <Button
              as={HashLink}
              onClick={() =>
                window.open("https://sasprinter.com/booknow", "_blank")
              }
              to="#"
              variant="outline-white"
              className="ms-3"
            >
              Book Now
            </Button>
          </div>
        </Container>
      </Navbar>
      <section
        className="section-header pt-1 pt-lg-2 pb-9 pb-lg-12 bg-primary overflow-hidden"
        id="home"
        style={{paddingTop: '2rem'}}
      >
        <Container>
          <Card>
            <Card.Body className="availability">
              <FullCalendar
                editable
                selectable
                ref={calendarRef}
                events={events}
                displayEventTime={false}
                themeSystem="bootstrap"
                plugins={[
                  dayGridPlugin,
                  timeGridPlugin,
                  bootstrapPlugin,
                  interactionPlugin,
                ]}
                initialView="dayGridMonth"
                initialDate={currentDate}
              />
            </Card.Body>
          </Card>
        </Container>
      </section>
    </>
  );
};
export default AvailabiltyPage;
