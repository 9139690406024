import React, { useState, useRef } from "react";
import moment from "moment-timezone";
import Datetime from "react-datetime";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import {
  Col,
  Row,
  Form,
  Modal,
  Button,
  InputGroup,
} from "@themesberg/react-bootstrap";
import { db } from "../firebase";
import accounts from "../data/accounts";
import paymentMethods from "../data/paymentMethods";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";

export const AddNewTransactionModal = (props) => {
  const [title, setTitle] = useState(props.title);

  const [selectedAccountId, setSelectedAccountId] = useState("");
  const [transactionCategories] = useState([]);
  const [customers] = useState([]);
  const [defaultCategory, setDefaultCategory] = useState("Trip");
  const [reimbursedRowClass, setReimbursedRowClass] = useState("d-sm-none");
  const [transactionDate, setTransactionDate] = useState(
    moment().format("MM/DD/YYYY")
  );
  const transDate = useRef(transactionDate);
  const transType = useRef("Income");
  const transCategory = useRef("");
  const transPaymentMethod = useRef("Cash");
  const transAmount = useRef(0);
  const transBankAccount = useRef("SA Sprinter");
  const transCustomer = useRef("Turo");
  const transNotes = useRef("");
  const transReimbursed = useRef(false);

  const { show = false, edit = false, id } = props;
  const onDelete = () => edit && props.onDelete && props.onDelete(id);
  const onHide = () => props.onHide && props.onHide();

  const SwalWithBootstrapButtons = withReactContent(
    Swal.mixin({
      customClass: {
        okayButton: "btn btn-primary me-3",
      },
      buttonsStyling: true,
    })
  );

  function toggleExpenseTransaction() {
    //console.log("toggleExpenseTransaction: " + transType.current.value);
    if (transType.current.value === "Expense") setReimbursedRowClass("");
    else {
      setReimbursedRowClass("d-sm-none");
      setDefaultCategory("Trip");
    }
  }

  const showConfirmationDialog = async (message) => {
    const result = await SwalWithBootstrapButtons.fire({
      icon: "info",
      title: message,
      text: "",
      showCancelButton: false,
      okayButtonText: "Okay",
    });

    if (result.isConfirmed) {
      onHide();
    }
  };

  const loadTransactionCategories = async () => {
    const data = await db
      .collection("transaction_categories")
      .orderBy("title")
      .get();
    for (var i = 0; i < data.docs.length; i++) {
      var catObj = data.docs[i].data();
      const category = {
        title: catObj.title,
      };
      transactionCategories.push(category);
    }
    //console.log("#2");
    //console.log("Categories: " + transactionCategories.length);
  };
  if (transactionCategories === null || transactionCategories.length === 0)
    loadTransactionCategories();

  const loadCustomers = async () => {
    const data = await db.collection("customers").orderBy("name").get();
    for (var i = 0; i < data.docs.length; i++) {
      var custObj = data.docs[i].data();
      const customer = {
        id: custObj.id,
        name: custObj.name,
        phone: custObj.phone,
        email: custObj.email,
        street_address: custObj.street_address,
        city: custObj.city,
        state: custObj.state,
        country: custObj.country,
      };
      customers.push(customer);
    }
    //console.log("#2");
    //console.log("Categories: " + customers.length);
  };
  if (customers === null || customers.length === 0) loadCustomers();

  async function setAccountIdByAccountName() {
    //console.log("setAccountIdByAccountName: " + transBankAccount.current.value);
    await accounts.map((account) => {
      //console.log(account.id + " => " + account.name + " => " + transBankAccount.current.value);
      if (account.name === transBankAccount.current.value) {
        setSelectedAccountId(account.id);
        console.log(
          "account.id: " +
            account.id +
            ", selectedAccountId: " +
            selectedAccountId
        );
      }
    });
  }

  const saveNewTransaction = async () => {
    if (
      transactionDate === "" ||
      transAmount.current.value === "" ||
      transAmount.current.value === 0
    ) {
      await SwalWithBootstrapButtons.fire(
        "Fields empty!",
        "Transaction date and amount is mandatory!",
        "warning"
      );
    } else {
      //console.log("Date: " + new Date(transactionDate));
      //setAccountIdByAccountName();
      var accountId;
      accounts.map((account) => {
        //console.log(account.id + " => " + account.name + " => " + transBankAccount.current.value);
        if (account.name === transBankAccount.current.value) {
          accountId = account.id;
          //console.log("account.id: " + account.id + ", selectedAccountId: " + accountId);
        }
      });
      //console.log("Selected account id: " + selectedAccountId);
      const transaction = {
        date: new Date(transactionDate),
        type: transType.current.value,
        customer: transCustomer.current.value,
        payment_method: transPaymentMethod.current.value,
        amount: transAmount.current.value,
        account: transBankAccount.current.value,
        category: transCategory.current.value,
        notes: transNotes.current.value,
        reimbursed: transReimbursed.current.value,
      };
      //console.log("saveNewTransaction: " + JSON.stringify(transaction));
      try {
        await db.collection("transactions").add(transaction);
        //console.log("Updating account: " + accountId);
        updateAccountBalance(
          accountId,
          Number(transAmount.current.value),
          transType.current.value
        );
        //await SwalWithBootstrapButtons.fire('Saved!', 'New transaction saved successfully.', 'success');
      } catch (err) {
        console.error(err);
        alert(err.message);
      }
    }
  };

  async function updateAccountBalance(accountId, amount, transactionType) {
    try {
      var accountRef = db.collection("accounts").doc(accountId);
      accountRef
        .get()
        .then((doc) => {
          if (doc.exists) {
            //console.log("Document data:", doc.data());
            const accountDetails = doc.data();
            //console.log("Account name: " + accountDetails.account_name);
            //console.log("Account balance: " + accountDetails.account_balance);

            var accountBalance = Number(accountDetails.account_balance);

            //console.log("Amount: " + amount);
            //console.log("accountBalance: " + accountBalance);
            if (transactionType === "Income")
              accountBalance = accountBalance + amount;
            else accountBalance = accountBalance - amount;

            db.collection("accounts")
              .doc(accountId)
              .update({
                account_balance: accountBalance,
              })
              .then(() => {
                //console.log("Account balance updated to " + accountBalance);
                showConfirmationDialog(
                  "Transaction saved successfully and updated account balance."
                );
                onHide();
                //alert("Transaction saved successfully and updated account balance.");
              });
          } else {
            console.log("Account not found");
            onHide();
          }
          onHide();
        })
        .catch((error) => {
          console.log("Error getting document:", error);
          onHide();
        });
    } catch (err) {
      console.error(err);
    }
  }

  return (
    <Modal as={Modal.Dialog} centered show={show} onHide={onHide}>
      <Form className="modal-content">
        <Modal.Body>
          <Form.Group id="title" className="mb-4">
            <Form.Label>Add New Transaction</Form.Label>
            {/* <Form.Control
              required
              autoFocus
              type="text"
              value={title}
              onChange={onTitleChange} /> */}
          </Form.Group>
          <Row>
            <Col xs={12} lg={6}>
              <Form.Group id="date">
                <Form.Label>Date</Form.Label>
                <Datetime
                  ref={transDate}
                  timeFormat={false}
                  closeOnSelect={true}
                  onChange={setTransactionDate}
                  renderInput={(props, openCalendar) => (
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faCalendarAlt} />
                      </InputGroup.Text>
                      <Form.Control
                        required
                        type="text"
                        value={
                          transactionDate
                            ? moment(transactionDate).format("MM/DD/YYYY")
                            : ""
                        }
                        placeholder="mm/dd/yyyy"
                        onFocus={openCalendar}
                        onChange={() => {}}
                      />
                    </InputGroup>
                  )}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6} className="mb-3">
              <Form.Group id="type">
                <Form.Label>Type</Form.Label>
                <Form.Select
                  defaultValue="Income"
                  ref={transType}
                  onChange={toggleExpenseTransaction}
                >
                  <option value="Income">Income</option>
                  <option value="Expense">Expense</option>
                </Form.Select>
              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group id="type">
                <Form.Label>Category</Form.Label>
                <Form.Select defaultValue={defaultCategory} ref={transCategory}>
                  {transactionCategories.map((category) => (
                    <option value={category.title}>{category.title}</option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6} className="mb-3">
              <Form.Group id="payment_method">
                <Form.Label>Payment Method</Form.Label>
                <Form.Select defaultValue="Cash" ref={transPaymentMethod}>
                  {paymentMethods.map((paymentMethod) => (
                    <option value={paymentMethod.name}>
                      {paymentMethod.name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group id="amount">
                <Form.Label>Amount</Form.Label>
                <Form.Control
                  required
                  type="number"
                  placeholder="0"
                  ref={transAmount}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6} className="mb-3">
              <Form.Group id="payment_method">
                <Form.Label>Bank Account</Form.Label>
                <Form.Select
                  defaultValue="SA Sprinter"
                  ref={transBankAccount}
                  onChange={setAccountIdByAccountName}
                >
                  {accounts.map((account) => (
                    <option value={account.name}>{account.name}</option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group id="payment_method">
                <Form.Label>Customer</Form.Label>
                <Form.Select defaultValue="Other" ref={transCustomer}>
                  {customers.map((customer) => (
                    <option value={customer.name}>{customer.name}</option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={12} className="mb-3">
              <Form.Group id="notes">
                <Form.Label>Comments/Notes</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="Add any comments/notes"
                  ref={transNotes}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className={reimbursedRowClass}>
            <Col md={6} className="mb-3">
              <Form.Group id="reimbursed">
                <Form.Check
                  label="Reimbursed?"
                  id="reimbursed"
                  htmlFor="defaultCheck5"
                  ref={transReimbursed}
                />
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            className="me-2"
            onClick={saveNewTransaction}
          >
            {edit ? "Update event" : "Save Transaction"}
          </Button>

          {edit ? (
            <Button variant="danger" onClick={onDelete}>
              Delete event
            </Button>
          ) : null}

          <Button variant="link" className="text-gray ms-auto" onClick={onHide}>
            Close
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};
