import React, { useEffect, useState } from "react";
import Chartist from "react-chartist";
import ChartistTooltip from 'chartist-plugin-tooltips-updated';

import { db } from "../firebase";
import moment from "moment";

function ExpenseValueChart() {

    const [transactions] = useState([]);

    const [data, setData] = useState();

    useEffect(() => {
        const loadIncomeTransactions = async () => {
            try {
            const transactionsData = await db.collection("transactions").orderBy("date", "asc").where("type", "==", "Expense").limit(10).get();
            var totalIncomeAmount = 0;
            var labels  = [];
            var series = [];
            for(var i = 0; i < transactionsData.docs.length; i++) {
                var transObj = transactionsData.docs[i].data();
                totalIncomeAmount = totalIncomeAmount + Number(transObj.amount);
                const transaction = {
                    date: moment(transObj.date.toDate().toLocaleString()).format("MMM DD"),
                    amount: Number(transObj.amount),
                }
                transactions.push(transaction);
                labels.push(transaction.date);
                series.push(transaction.amount);
            }
            //console.log("transactions: " + JSON.stringify(transactions));

            const tempData = {
                labels : labels,
                series : [series],
            }

            setData(tempData);
            //console.log("tempData: " + JSON.stringify(tempData));
          } catch(error) {
            console.log("Expense transaction firebase error: " + error);
          }
        };

        loadIncomeTransactions();
    }, [])

//   const data = {
//     labels: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
//     series: [[1, 2, 2, 3, 3, 4, 3]]
//   };

  const options = {
    low: 0,
    showArea: true,
    fullWidth: true,
    axisX: {
      position: 'end',
      showGrid: true
    },
    axisY: {
      // On the y-axis start means left and end means right
      showGrid: false,
      showLabel: false,
      labelInterpolationFnc: value => `$${value / 1}k`
    }
  };

  const plugins = [
    ChartistTooltip()
  ]

  return (
    <Chartist data={data} options={{...options, plugins}} type="Line" className="ct-series-g ct-double-octave" />
  );
}

export default ExpenseValueChart;