import React, { useEffect, useState } from "react";
import { Col, Row, Card, Image } from '@themesberg/react-bootstrap';
import { Link } from "react-router-dom";
import { Routes } from "../routes";
import moment from "moment";
import { db } from "../firebase";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import LoadingIcon from "../assets/img/loading.gif";

export const TripInProgress = () => {

    const [bookings] = useState([]);
    const[loading, setLoading] = useState(true);

    useEffect(() => {
        const loadingTripInProgress = async () => {
        console.log("loadingTripInProgress");
        try {
            var startDate = moment().toDate();
            const data = await db.collection("bookings").where("start", "<=", startDate).orderBy("start", "desc").limit(1).get();
            for(var i = 0; i < data.docs.length; i++) {
                var bookingObj = data.docs[i].data();
                if(startDate < moment(bookingObj.end.toDate())) {
                  const booking = {
                      id: bookingObj.id,
                      title: bookingObj.title,
                      start: moment(bookingObj.start.toDate().toLocaleString()).format("YYYY-MM-DD"),
                      end: moment(bookingObj.end.toDate().toLocaleString()).format("YYYY-MM-DD"),
                      startMiles: bookingObj.startMiles,
                      endMiles: bookingObj.endMiles,
                      pickupTime: bookingObj.pickupTime,
                      dropoffTime: bookingObj.dropoffTime,
                      tripCost: bookingObj.tripCost,
                      settlementAmount: bookingObj.settlementAmount,
                      notes: bookingObj.notes,
                      tripType: bookingObj.tripType,
                      className: bookingObj.className
                  };
                  bookings.push(booking);
                }
            }
            //setBookings(bookings);
            //console.log("Trip in progress: " + bookings.length);
            //console.log("Bookings: " + JSON.stringify(bookings));
            setLoading(false);
        } catch(err) {
            console.log("Error while retrieving upcomgin bookings: " + err);
        }
        };
        loadingTripInProgress();
    }, []);

    const SwalWithBootstrapButtons = withReactContent(Swal.mixin({
      customClass: {
        okayButton: 'btn btn-primary me-3',
      },
      buttonsStyling: true
    }));

    const showTripInformationDialog = async (message) => {
      const result = await SwalWithBootstrapButtons.fire({
        icon: 'info',
        title: message,
        text: '',
        showCancelButton: false,
        okayButtonText: "Okay"
      });
    };

    const onTripClick = (title, startDate, endDate, pickupTime, dropoffTime, organizer, startMiles, notes) => {
      //console.log("onTripClick");
      /*const tripDetailsJson = {
        title: title,
        startDate: startDate,
        endDate: endDate,
        pickupTime: pickupTime,
        organizer: organizer,
        startMiles: startMiles,
        notes: notes
      }*/
      //setTripDetails("details", JSON.stringify(tripDetailsJson));
      //console.log("tripDetails: " + trip.details.title);
      //const { event: { title, startDate, endDate, pickupTime, organizer } } = props;
      //console.log("Booking to edit: " + title + " start: " + start + ", end: " + end);
      //setModalProps({ id, title, start, end });
      var tripDetailsHtml = "<b>" + title + "</b>";
      tripDetailsHtml = tripDetailsHtml + "<br>Trip type: " + organizer;
      tripDetailsHtml = tripDetailsHtml + "<br>Start date: " + startDate.format('ddd, D MMM YYYY');
      tripDetailsHtml = tripDetailsHtml + "<br>Pickup time: " + pickupTime;
      tripDetailsHtml = tripDetailsHtml + "<br>End date: " + endDate.format('ddd, D MMM YYYY');
      tripDetailsHtml = tripDetailsHtml + "<br>Dropoff time: " + dropoffTime;
      tripDetailsHtml = tripDetailsHtml + "<br>Start miles: " + startMiles;
      tripDetailsHtml = tripDetailsHtml + "<br>Comments: " + notes;
      showTripInformationDialog(tripDetailsHtml);
    };

    const Trip = (props) => {
      const { organizer, startDate, endDate, title, onlyTime, lastItem, pickupTime, dropoffTime, startMiles, notes } = props
        , startDateTime = startDate.format('HH:mm A')
        , startDateMonth = startDate.format('MMM')
        , startDateDay = startDate.format('D')
        , startDateFormatted = startDate.format('ddd, D MMM')
        , endDateFormatted = endDate.format('ddd, D MMM YYYY')
        , eventDuration = `${startDateFormatted} - ${endDateFormatted}`;
  
      return (
        <Row className={`align-items-center d-block d-sm-flex ${lastItem ? "" : "border-bottom border-light pb-4 mb-4"}`}>
          <Col className="col-auto mb-3 mb-sm-0">
            <div className="calendar d-flex">
              <span className="calendar-month">{startDateMonth}</span>
              <span className="calendar-day">{startDateDay}</span>
            </div>
          </Col>
          <Col>
          <Card.Link onClick={() => onTripClick(title, startDate, endDate, pickupTime, dropoffTime, organizer, startMiles, notes)}>
              <h5>{title}</h5>
            </Card.Link>
            <span>Trip type: <b>{organizer}</b></span>
            <div className="small fw-bold mt-1">{onlyTime ? `Time: ${startDateTime}` : eventDuration}</div>
            <span className="small fw-bold">Dropoff time: {dropoffTime}</span>
            {/* <span className="small fw-bold">Place: {address}</span> */}
          </Col>
        </Row>
      );
    };
  
    return (
      <Card border="light" className="shadow-sm">
        <Card.Header className="border-bottom border-light">
          <h2 className="h5 mb-0">Trip In Progress</h2>
        </Card.Header>
        {loading ?
          <Card.Body>
            <Image src={LoadingIcon} />
          </Card.Body>
        :
        <>
        <Card.Body hidden={bookings.length > 0}>
          <h6><i>No trips in progress!</i></h6>
        </Card.Body>
        <Card.Body>
            {bookings.map((booking, index) => (
                <Trip
                  organizer={booking.tripType}
                  startDate={moment(booking.start)}
                  endDate={moment(booking.end)}
                  title={booking.title}
                  pickupTime={booking.pickupTime}
                  dropoffTime={booking.dropoffTime}
                  startMiles={booking.startMiles}
                  notes={booking.notes}
                  lastItem={(index === (booking.length-1))}
                />
            ))}
        </Card.Body>
        {/* <Card.Footer className="border-top border-light text-center">
          <a className="fw-bold text-primary" href="/bookings/">See all</a>
        </Card.Footer> */}
        </>
        }
      </Card>
    );
  };