
import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartLine, faCloudUploadAlt, faPlus, faRocket, faTasks, faUserShield } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Button, Dropdown, ButtonGroup } from '@themesberg/react-bootstrap';

import { AccountChart } from "../components/AccountChart";
import { AccountsWidget } from "../components/AccountsWidget";
import { db } from "../firebase";

function AccountSummary() {

  const [totalIncome, setTotalIncome] = useState(0);
  const [totalExpense, setTotalExpense] = useState(0);
  const [accounts, setAccounts] = useState([]);

  useEffect(() => {
    const calculateTotalIncome = async () => {
      const data = await db.collection("transactions").get();
      var totalIncomeAmount = 0;
      var totalExpenseAmount = 0;
      for(var i = 0; i < data.docs.length; i++) {
        var transObj = data.docs[i].data();
        if(transObj.type === "Income")
          totalIncomeAmount = totalIncomeAmount + Number(transObj.amount);
        else if(transObj.type === "Expense")
          totalExpenseAmount = totalExpenseAmount + Number(transObj.amount);
      }
      setTotalIncome(totalIncomeAmount);
      setTotalExpense(totalExpenseAmount);
    };

    calculateTotalIncome();

    const accountSummary = async () => {
      const accountData = await db.collection("accounts").orderBy("account_name").get();
      let acc = []
      for(var i = 0; i < accountData.docs.length; i++) {
        var accountObj = accountData.docs[i].data();
        const account = {
          name: accountObj.account_name,
          balance: accountObj.account_balance,
        }
        acc.push(account);
      }
      setAccounts(acc);
      console.log("accounts: " + JSON.stringify(accounts));
    };

    accountSummary();
  }, [])

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        
      </div>

      <h4>Account Summary</h4>
      <Row className="justify-content-md-center">
        {accounts.map(account => (
          <Col xs={12} sm={6} xl={4} className="mb-4">
            <AccountsWidget
              category={account.name}
              title={"$" + parseFloat(account.balance).toFixed(2)}
              // period="Feb 1 - Apr 1"
              // percentage={18.2}
              icon={faChartLine}
              iconColor="shape-secondary"
            />
          </Col>
        ))}
      </Row>

      <Row>
        <Col xs={12}>
          <AccountChart
            title="Income Summary"
            value={parseFloat(totalIncome).toFixed(2)}
            incomeClass="p-2"
            expenseClass="d-sm-none"
          />
        </Col>        
      </Row>

      <Row>
      <Col xs={12}>
        &nbsp;
      </Col>
      </Row>

      <Row>
        <Col xs={12}>
          <AccountChart
            title="Expense Summary"
            value={parseFloat(totalExpense).toFixed(2)}
            incomeClass="d-sm-none"
            expenseClass="p-2"
          />
        </Col>    
      </Row>
    </>
  );
}

export default AccountSummary;
