
import React, { useState, useEffect } from "react";
import { faChartLine } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Image } from '@themesberg/react-bootstrap';

import { AccountsWidget } from "../components/AccountsWidget";
import { db } from "../firebase";
import { UpcomingBookingsWidget } from "../components/UpcomingBookings";
import { TripInProgress } from "../components/TripInProgress";
import LoadingIcon from "../assets/img/loading.gif";

function Dashboard() {

  const [accounts] = useState([]);
  const[loading, setLoading] = useState(true);

  useEffect(() => {
    const accountSummary = async () => {
      const accountData = await db.collection("accounts").orderBy("account_name").get();
      for(var i = 0; i < accountData.docs.length; i++) {
        var accountObj = accountData.docs[i].data();
        const account = {
          name: accountObj.account_name,
          balance: accountObj.account_balance,
        }
        accounts.push(account);
      }
      //console.log("accounts: " + JSON.stringify(accounts));
      setLoading(false);
    };

    accountSummary();
  }, [])

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        
      </div>

      <Row>
        <Col xs={12}>
          <TripInProgress />
        </Col>
        <p></p>
        <Col xs={12}>
          <UpcomingBookingsWidget />
        </Col>
      </Row>

      <Row>
      <Col xs={12}>
        &nbsp;
      </Col>
      </Row>
      <Row className="justify-content-md-center">
        {/* <Col xs={12} className="mb-4 d-none d-sm-block">
          <AccountChart
            title="Income Summary"
            value={totalIncome}
            incomeClass="p-2"
            expenseClass="d-sm-none"
          />
        </Col> */}
        <Col xs={12} className="mb-4 d-none d-sm-block">
          <h4>Account Summary</h4>
        </Col>
        { loading ?
          <Col xs={12} sm={6} xl={4} className="mb-4">
            <Image src={LoadingIcon} />
          </Col>
          :
          <>
          {accounts.map(account => (
            <Col xs={12} sm={6} xl={4} className="mb-4">
              <AccountsWidget
                category={account.name}
                title={"$" + parseFloat(account.balance).toFixed(2)}
                // period="Feb 1 - Apr 1"
                // percentage={18.2}
                icon={faChartLine}
                iconColor="shape-secondary"
              />
            </Col>
          ))}   
          </>  
        }   
      </Row>
    </>
  );
}

export default Dashboard;
