
export default [
    {
        "id": "5zlDAdS2wI0qMumjSiWF",
        "name": "SA Sprinter",
        "sl":1,
    },
    {
        "id": "SVQzHCQHOcAPVnWZSEsi",
        "name": "Amith",
        "sl":2,
    },
    {
        "id": "PkKKDX6TpmgBgMdGBizm",
        "name": "Sreejith",
        "sl":3,
    },
    {
        "id": "uLGpxDWLSf2RVrFsdFJN",
        "name": "Shan",
        "sl":4,
    },
]