import React, { useRef, useState, useEffect } from "react";
import moment from "moment-timezone";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";
import withReactContent from "sweetalert2-react-content";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import bootstrapPlugin from "@fullcalendar/bootstrap";
import interactionPlugin from "@fullcalendar/interaction";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { faQuestionCircle } from "@fortawesome/free-regular-svg-icons";
import { Card, Button, Breadcrumb } from '@themesberg/react-bootstrap';

import { Routes } from "../routes";
import { BookingModal } from "../components/BookingModal";
import EVENTS_DATA from "../data/events";
import { db } from "../firebase";
import Loader from "react-loader-spinner";

const SwalWithBootstrapButtons = withReactContent(Swal.mixin({
  customClass: {
    confirmButton: 'btn btn-primary me-3',
    cancelButton: 'btn btn-gray'
  },
  buttonsStyling: false
}));

export default () => {
  const defaultModalProps = { id: "", title: "", start: null, end: null, notes: "", startMiles: null, endMiles: null, pickupTime: null, dropoffTime: null, settlementAmount: null, tripType: "Leisure" };
  const history = useHistory();
  //const [events, setEvents] = useState(EVENTS_DATA);
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [modalProps, setModalProps] = useState(defaultModalProps);
  const calendarRef = useRef();

  const currentDate = moment().format("YYYY-MM-DD");

  const showConfirmationDialog = async (message) => {
    const result = await SwalWithBootstrapButtons.fire({
      icon: 'info',
      title: message,
      text: '',
      showCancelButton: false,
      okayButtonText: "Okay"
    });
  };

  useEffect(() => {
    const loadEvents = async () => {
      console.log("Load bookings");
      try {
        const data = await db.collection("bookings").orderBy('start', 'desc').get();
        const bookings = [];
        for(var i = 0; i < data.docs.length; i++) {
          var bookingObj = data.docs[i].data();
          const booking = {
            id: bookingObj.id,
            title: bookingObj.title,
            start: moment(bookingObj.start.toDate()).format("YYYY-MM-DD"),
            end: moment(bookingObj.end.toDate()).format("YYYY-MM-DD HH:mm"),
            startMiles: bookingObj.startMiles,
            endMiles: bookingObj.endMiles,
            pickupTime: bookingObj.pickupTime,
            dropoffTime: bookingObj.dropoffTime,
            tripCost: bookingObj.tripCost,
            settlementAmount: bookingObj.settlementAmount,
            notes: bookingObj.notes,
            tripType: bookingObj.tripType,
            className: bookingObj.className
          };
          //console.log(booking.title + " => " + booking.start + " => " + booking.end);
          bookings.push(booking);
        }
        console.log(bookings)
        setEvents(bookings);
        console.log("Total bookings: " + bookings.length);
        //console.log("Bookings: " + JSON.stringify(bookings));
        setLoading(false);
      } catch(err) {
        console.log("Error while retrieving bookings: " + err);
      }
    };
    loadEvents();
  }, []);

  const onDateClick = (props) => {
    const { date } = props;
    const id = events.length + 100;
    const endDate = new Date(date);

    setModalProps({ ...modalProps, id, start: date, end: endDate });
    setShowAddModal(true);
  };

  const onEventClick = (props) => {
    //console.log("onEventClick");
    const { event: { id, title, start, end } } = props;
    //console.log("Booking to edit: " + title + " start: " + start + ", end: " + end);
    setModalProps({ id, title, start, end });
    setShowEditModal(true);
  };

  const onEventUpdate = (docId, props) => {
    //console.log("onEventUpdate: " + docId);
    //const { id, title, start, end } = props;
    const { id, title, start, end, notes, startMiles, endMiles, pickupTime, dropoffTime, settlementAmount, tripType, tripCost } = props;
    const calendarApi = calendarRef.current.getApi();
    const calendarElem = calendarApi.getEventById(id);

    if (calendarElem) {
      calendarElem.setProp("title", title);
      calendarElem.setStart(start);
      calendarElem.setEnd(end);

      const booking = {
        id: id,
        title: title,
        start: moment(start),
        end: moment(end),
        startMiles: startMiles,
        endMiles: endMiles,
        pickupTime: pickupTime,
        dropoffTime: dropoffTime,
        tripCost: tripCost,
        settlementAmount: settlementAmount,
        notes: notes,
        tripType: tripType
      }

      updateBooking(docId, booking);
    }

    setShowEditModal(false);
  };

  async function updateBooking (docId, booking) {
    //console.log("updateBooking: " + docId + " | " + booking.title);
    try {
      await db.collection("bookings").doc(docId)
        .update({
          title: booking.title,
          start: moment(booking.start).toDate(),
          end: moment(booking.end).toDate(),
          startMiles: booking.startMiles,
          endMiles: booking.endMiles,
          pickupTime: booking.pickupTime,
          dropoffTime: booking.dropoffTime,
          tripCost: booking.tripCost,
          settlementAmount: booking.settlementAmount,
          notes: booking.notes,
          tripType: booking.tripType
        })
        .then(() => {
          showConfirmationDialog("Selected booking updated successfully!");
        });
    } catch(error) {
      console.log("updateBooking Error: " + error);
      await SwalWithBootstrapButtons.fire('Error!', 'Could not update the booking!', 'error');  
    }
  }

  const onEventAdd = (props) => {
    setLoading(true);
    const newEvent = { ...props, dragable: true, className: 'bg-blue text-white', allDay: false };
    //console.log("newEvent: " + JSON.stringify(newEvent));
    try {
      console.log("newEvent: " + JSON.stringify(newEvent));
      db.collection("bookings").add(newEvent);
    } catch (err) {
      console.error(err);
      alert(err.message);
    }
    setShowAddModal(false);
    setEvents([...events, newEvent]);
    setModalProps(defaultModalProps);
    setLoading(false);
  };

  const onEventDelete = async (id, docId) => {
    //console.log("onEventDelete: " + docId);
    const result = await SwalWithBootstrapButtons.fire({
      icon: 'error',
      title: 'Confirm deletion',
      text: 'Are you sure you want to delete this booking?',
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: 'No, cancel!'
    });

    setShowEditModal(false);
    setModalProps(defaultModalProps);

    if (result.isConfirmed) {
      try {
        await db.collection('bookings').doc(docId).delete();
        const newEvents = events.filter(e => e.id !== parseInt(id));
        setEvents(newEvents);
        await SwalWithBootstrapButtons.fire('Deleted!', 'The event has been deleted.', 'success');
      } catch(error) {
        await SwalWithBootstrapButtons.fire('Error!', 'Could not delete the booking', 'error');  
      }
    }
  };

  const handleClose = () => {
    setShowAddModal(false);
    setShowEditModal(false);
  };

  const goToCalendarDocs = () => {
    history.push(Routes.PluginCalendar.path);
  };

  return (
    <>
      {showEditModal ? (
        <BookingModal
          {...modalProps}
          edit={true}
          show={showEditModal}
          onUpdate={onEventUpdate}
          onDelete={onEventDelete}
          onHide={handleClose}
        />
      ) : null}

      {showAddModal ? (
        <BookingModal
          {...modalProps}
          show={showAddModal}
          onAdd={onEventAdd}
          onHide={handleClose}
        />
      ) : null}

      <div className="d-xl-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-xl-0">
          <h4>Bookings</h4>
          <p className="mb-0">
            SA Sprinter booking schedules
          </p>
        </div>
        {/* <div>
          <Button variant="outline-gray" onClick={goToCalendarDocs}>
            <FontAwesomeIcon icon={faQuestionCircle} className="me-1" /> Calendar Docs
          </Button>
        </div> */}
      </div>

      {loading ? 
        <div className="loading_div">
          <Loader
            type="Puff"
            color="#00BFFF"
            height={75}
            width={75}
          />
        </div>
        :
      <Card>
        <Card.Body>
          <FullCalendar
            editable
            selectable
            ref={calendarRef}
            events={events}
            displayEventTime={false}
            themeSystem="bootstrap"
            plugins={[dayGridPlugin, timeGridPlugin, bootstrapPlugin, interactionPlugin]}
            initialView="dayGridMonth"
            initialDate={currentDate}
            eventClick={onEventClick}
            dateClick={onDateClick}
          />
        </Card.Body>
      </Card>
      }
    </>
  );
};
