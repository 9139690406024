
export default [
    {
        "id": 1,
        "title": 'Leisure',
    },
    {
        "id": 2,
        "title": 'Airport',
    },
    {
        "id": 3,
        "title": 'Business',
    },
    {
        "id": 4,
        "title": 'Other',
    },
];