import React, { useState } from "react";
import moment from "moment-timezone";
import Datetime from "react-datetime";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt, faDollarSign } from "@fortawesome/free-solid-svg-icons";
import {
  Col,
  Row,
  Form,
  Modal,
  Button,
  InputGroup,
} from "@themesberg/react-bootstrap";
import accounts from "../data/accounts";

export const AddNewTransferModal = (props) => {
  const {
    show = false,
    transDate,
    setTransactionDate,
    transactionDate,
    transAmount,
    transNotes,
    performNewTransfer,
    fromBankAccount,
    setAccountIdByAccountName,
    toBankAccount,
    accountsList,
    editTransfer,
    editTransferObj,
    performUpdateTransfer,
  } = props;

  const onHide = () => props.onHide && props.onHide();
  return (
    <Modal as={Modal.Dialog} centered show={show} onHide={onHide}>
      {editTransfer ? (
        <Form className="modal-content">
          <Modal.Body>
            <Form className="account-transfer-form">
              <Form.Group id="title" className="mb-4">
                <Form.Label>update account transfer details</Form.Label>
              </Form.Group>
              <Row>
                <Col xs={12} lg={6}>
                  <Form.Group id="date">
                    <Form.Label>Date</Form.Label>
                    <Datetime
                      ref={transDate}
                      closeOnSelect={true}
                      timeFormat={false}
                      onChange={setTransactionDate}
                      defaultValue={editTransferObj?.date}
                      renderInput={(props, openCalendar) => (
                        <InputGroup>
                          <InputGroup.Text>
                            <FontAwesomeIcon icon={faCalendarAlt} />
                          </InputGroup.Text>
                          <Form.Control
                            required
                            type="text"
                            value={
                              transactionDate
                                ? moment(transactionDate).format("MM/DD/YYYY")
                                : ""
                            }
                            placeholder="mm/dd/yyyy"
                            onFocus={openCalendar}
                            onChange={() => {}}
                          />
                        </InputGroup>
                      )}
                    />
                  </Form.Group>
                </Col>
                <Col md={6} className="mb-3">
                  <Form.Group id="from_account">
                    <Form.Label>From Account</Form.Label>
                    <Form.Select
                      defaultValue={editTransferObj?.from_account}
                      ref={fromBankAccount}
                      onChange={() => setAccountIdByAccountName("from")}
                    >
                      {accountsList.map((account) => (
                        <option value={account.name}>{account.name}</option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={6} className="mb-3">
                  <Form.Group id="to_account">
                    <Form.Label>To Account</Form.Label>
                    <Form.Select
                      defaultValue={editTransferObj?.to_account}
                      ref={toBankAccount}
                      onChange={() => setAccountIdByAccountName("to")}
                    >
                      {accountsList.map((account) => (
                        <option value={account.name}>{account.name}</option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col md={6} className="mb-3">
                  <Form.Group id="amount">
                    <Form.Label>Amount</Form.Label>
                    <Form.Control
                      required
                      type="number"
                      defaultValue={editTransferObj?.amount}
                      placeholder="0"
                      ref={transAmount}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={12} className="mb-3">
                  <Form.Group id="notes">
                    <Form.Label>Comments/Notes</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      defaultValue={editTransferObj?.notes}
                      placeholder="Add any comments/notes"
                      ref={transNotes}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="outline-primary"
              size="sm"
              onClick={() => performUpdateTransfer()}
            >
              Update Transfer{" "}
              <FontAwesomeIcon icon={faDollarSign} className="me-2" />
            </Button>

            <Button
              variant="link"
              className="text-gray ms-auto cursor-pointer"
              onClick={onHide}
            >
              Close
            </Button>
          </Modal.Footer>
        </Form>
      ) : (
        <Form className="modal-content">
          <Modal.Body>
            <Form className="account-transfer-form">
              <Form.Group id="title" className="mb-4">
                <Form.Label>Initiate account transfer</Form.Label>
              </Form.Group>
              <Row>
                <Col xs={12} lg={6}>
                  <Form.Group id="date">
                    <Form.Label>Date</Form.Label>
                    <Datetime
                      ref={transDate}
                      closeOnSelect={true}
                      timeFormat={false}
                      onChange={setTransactionDate}
                      renderInput={(props, openCalendar) => (
                        <InputGroup>
                          <InputGroup.Text>
                            <FontAwesomeIcon icon={faCalendarAlt} />
                          </InputGroup.Text>
                          <Form.Control
                            required
                            type="text"
                            value={
                              transactionDate
                                ? moment(transactionDate).format("MM/DD/YYYY")
                                : ""
                            }
                            placeholder="mm/dd/yyyy"
                            onFocus={openCalendar}
                            onChange={() => {}}
                          />
                        </InputGroup>
                      )}
                    />
                  </Form.Group>
                </Col>
                <Col md={6} className="mb-3">
                  <Form.Group id="from_account">
                    <Form.Label>From Account</Form.Label>
                    <Form.Select
                      defaultValue="SA Sprinter"
                      ref={fromBankAccount}
                      onChange={() => setAccountIdByAccountName("from")}
                    >
                      {accountsList.map((account) => (
                        <option value={account.name}>{account.name}</option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={6} className="mb-3">
                  <Form.Group id="to_account">
                    <Form.Label>To Account</Form.Label>
                    <Form.Select
                      defaultValue="SA Sprinter"
                      ref={toBankAccount}
                      onChange={() => setAccountIdByAccountName("to")}
                    >
                      {accountsList.map((account) => (
                        <option value={account.name}>{account.name}</option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col md={6} className="mb-3">
                  <Form.Group id="amount">
                    <Form.Label>Amount</Form.Label>
                    <Form.Control
                      required
                      type="number"
                      placeholder="0"
                      ref={transAmount}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={12} className="mb-3">
                  <Form.Group id="notes">
                    <Form.Label>Comments/Notes</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      placeholder="Add any comments/notes"
                      ref={transNotes}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="outline-primary"
              size="sm"
              onClick={() => performNewTransfer()}
            >
              Make Transfer{" "}
              <FontAwesomeIcon icon={faDollarSign} className="me-2" />
            </Button>

            <Button
              variant="link"
              className="text-gray ms-auto cursor-pointer"
              onClick={onHide}
            >
              Close
            </Button>
          </Modal.Footer>
        </Form>
      )}
    </Modal>
  );
};
