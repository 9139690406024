/* eslint-disable array-callback-return */
import React, { useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChartLine, faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import {
  Card,
  Table,
  Pagination,
  Col,
  Row,
  Form,
  Button,
  InputGroup,
} from "@themesberg/react-bootstrap";
import { db } from "../firebase";
import "../scss/style.css";
import moment from "moment-timezone";
import accounts from "../data/accounts";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Loading from "./components/Loading";
import { AccountsWidget } from "../components/AccountsWidget";
import Datetime from "react-datetime";

function Reports() {
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const SwalWithBootstrapButtons = withReactContent(
    Swal.mixin({
      customClass: {
        confirmButton: "btn btn-primary me-3",
        cancelButton: "btn btn-gray",
      },
      buttonsStyling: true,
    })
  );

  const [transactions, setTransactions] = useState([]);
  const [filteredTransactions, setFilteredTransactions] = useState([]);
  const [transFilterString, setTransFilterString] = useState("");
  const [recordsPerPage, setRecordsPerPage] = useState(10);
  const [paginationElements, setPaginationItems] = useState([]);
  const [currentAssetPage, setCurrentAssetPage] = useState(0);
  const [startAssetRecord, setStartAssetRecord] = useState(1);
  const [endAssetRecord, setEndAssetRecord] = useState(recordsPerPage);
  const [disablePrev, setDisablePrev] = useState(false);
  const [disableNext, setDisableNext] = useState(false);
  const [editTransactionObj, setEditTransactionObj] = useState({});
  const [customers, setCustomers] = useState([]);
  const [transactionCategories, settransactionCategories] = useState([]);
  const [showReports, setShowReports] = useState(false);
  const firstDate = moment()
    .startOf("month")
    .startOf("day")
    .format("YYYY-MM-DD");
  const [start, setStart] = useState(firstDate);
  const [selectedAccountId, setSelectedAccountId] = useState("");
  const [sumAmount, setSumAmount] = useState(0);
  const startDate = start
    ? moment(start).format("YYYY-MM-DD")
    : moment().format("YYYY-MM-DD");
  const [end, setEnd] = useState(null);
  const endDate = end
    ? moment(end).endOf("day").format("YYYY-MM-DD")
    : moment().format("YYYY-MM-DD");

  const transType = useRef(null);
  const transCategory = useRef(null);
  const transBankAccount = useRef(null);
  const transCustomer = useRef(null);
  const loadCustomers = async () => {
    const data = await db.collection("customers").get();
    let a = [];
    for (var i = 0; i < data.docs.length; i++) {
      var custObj = data.docs[i].data();
      const customer = {
        id: custObj.id,
        name: custObj.name,
        phone: custObj.phone,
        email: custObj.email,
        street_address: custObj.street_address,
        city: custObj.city,
        state: custObj.state,
        country: custObj.country,
      };
      a.push(customer);
    }
    setCustomers(a);
  };
  const loadTransactionCategories = async () => {
    const data = await db
      .collection("transaction_categories")
      .orderBy("title")
      .get();
    let b = [];
    for (var i = 0; i < data.docs.length; i++) {
      var catObj = data.docs[i].data();
      const category = {
        title: catObj.title,
      };
      b.push(category);
    }
    settransactionCategories(b);
  };
  useEffect(() => {
    loadCustomers();
    loadTransactionCategories();
  }, []);

  function onTransFilterStringChange(e) {
    setLoading(true);
    //console.log("onTransFilterStringChange: " + e.target.value);
    setTransFilterString(e.target.value);

    var tempTransactions = [];
    var keyword = e.target.value.toUpperCase();
    var index = 0;
    transactions.map((transaction) => {
      //console.log("onTransFilterStringChange > " + keyword + " => " + transaction.type.toUpperCase());
      if (
        transaction.type.toUpperCase().includes(keyword) ||
        transaction.category.toUpperCase().includes(keyword) ||
        transaction.account.toUpperCase().includes(keyword) ||
        transaction.customer.toUpperCase().includes(keyword)
      ) {
        tempTransactions[index] = transaction;
        index = index + 1;
      }
    });
    setFilteredTransactions(tempTransactions);
    setLoading(false);
    //console.log("filteredTransactions => " + filteredTransactions.length);
  }

  async function loadTransactions() {
    setLoading(true);

    try {
      for (var i = 0; i < transactions.length; i++) {
        transactions.pop(i);
      }
      let start = new Date("2022-02-03");
      let end = new Date("2022-02-05");
      let dbRefs = db.collection("transactions");
      let a = "a";
      let am = "140";
      let name = a ? dbRefs.where("type", "==", "Expense") : dbRefs;
      let amount = am ? name.where("amount", "==", "140") : name;
      const data = await amount.get();
      //console.log("data.docs.length" + data.docs.length);
      let transactionsArr = [];
      for (var i = 0; i < data.docs.length; i++) {
        var transObj = data.docs[i].data();
        var docId = data.docs[i].id;
        const transaction = {
          id: docId,
          date: transObj.date.toDate().toLocaleString(),
          type: transObj.type,
          customer: transObj.customer,
          payment_method: transObj.payment_method,
          amount: transObj.amount,
          account: transObj.account,
          category: transObj.category,
          account_ref: transObj.account_ref,
          customer_ref: transObj.customer_ref,
        };
        transactionsArr.push(transaction);
        setTransactions(transactionsArr);
        setFilteredTransactions(transactionsArr);
        let num = Math.ceil(transactionsArr.length / recordsPerPage);
        let pagiArr = [];
        for (let i = 1; i <= num; i++) {
          let item = { value: i, active: i === 1 ? true : false };
          pagiArr.push(item);
        }
        setCurrentAssetPage(1);
        setDisablePrev(true);
        if (pagiArr.length > 1) {
          setDisableNext(false);
        } else {
          setDisableNext(true);
        }
        setPaginationItems(pagiArr);
      }

      setLoading(false);
    } catch (err) {
      console.log("Error while retrieving transactions: " + err);
    }
  }

  function handlePageChange(e, index) {
    setCurrentAssetPage(e.value);
    setStartAssetRecord((e.value - 1) * recordsPerPage + 1);
    setEndAssetRecord(e.value * recordsPerPage);
    let arr = [...paginationElements];
    arr.forEach((item) => {
      if (item.value === e.value) {
        item.active = true;
      } else {
        item.active = false;
      }
    });
    setPaginationItems(arr);
    if (e.value === arr.length) {
      setDisableNext(true);
    } else {
      setDisableNext(false);
    }
    if (e.value === 1) {
      setDisablePrev(true);
    } else {
      setDisablePrev(false);
    }
  }
  const handlePrev = () => {
    setCurrentAssetPage(currentAssetPage - 1);
    setStartAssetRecord((currentAssetPage - 1 - 1) * recordsPerPage + 1);
    setEndAssetRecord((currentAssetPage - 1) * recordsPerPage);
    let arr = [...paginationElements];
    arr.forEach((item) => {
      if (item.value === currentAssetPage - 1) {
        item.active = true;
      } else {
        item.active = false;
      }
    });
    setPaginationItems(arr);
    if (currentAssetPage - 1 === arr.length) {
      setDisableNext(true);
    } else {
      setDisableNext(false);
    }
    if (currentAssetPage - 1 === 1) {
      setDisablePrev(true);
    } else {
      setDisablePrev(false);
    }
  };
  const handleNext = () => {
    setCurrentAssetPage(currentAssetPage + 1);
    setStartAssetRecord((currentAssetPage + 1 - 1) * recordsPerPage + 1);
    setEndAssetRecord((currentAssetPage + 1) * recordsPerPage);
    let arr = [...paginationElements];
    arr.forEach((item) => {
      if (item.value === currentAssetPage + 1) {
        item.active = true;
      } else {
        item.active = false;
      }
    });
    setPaginationItems(arr);
    if (currentAssetPage + 1 === arr.length) {
      setDisableNext(true);
    } else {
      setDisableNext(false);
    }
    if (currentAssetPage + 1 === 1) {
      setDisablePrev(true);
    } else {
      setDisablePrev(false);
    }
  };

  const toggleUpdateModal = (item) => {
    setEditTransactionObj(item);
    setShowUpdateModal(true);
  };

  async function updateAccountBalance(transactionObj, transactionId) {
    console.log("updateAccountBalance for transactionId: " + transactionId);
    try {
      const transactionType = transactionObj.type;
      const transactionAccount = transactionObj.account;
      const transactionAmount = transactionObj.amount;
      console.log(
        "transactionType: " +
          transactionType +
          ", transactionAccount: " +
          transactionAccount +
          ", transactionAmount: " +
          transactionAmount
      );

      var accountId;
      accounts.map((account) => {
        //console.log(account.id + " => " + account.name + " => " + transBankAccount.current.value);
        if (account.name === transactionAccount) {
          accountId = account.id;
          //console.log("account.id: " + account.id + ", selectedAccountId: " + accountId);
        }
      });
      console.log("accountId: " + accountId);

      var accountRef = db.collection("accounts").doc(accountId);
      accountRef
        .get()
        .then((doc) => {
          if (doc.exists) {
            console.log("Account found");
            //console.log("Document data:", doc.data());
            const accountDetails = doc.data();
            //console.log("Account name: " + accountDetails.account_name);
            //console.log("Account balance: " + accountDetails.account_balance);

            var accountBalance = Number(accountDetails.account_balance);
            const amount = Number(transactionAmount);

            //console.log("Amount: " + amount);
            //console.log("accountBalance: " + accountBalance);
            if (transactionType === "Income")
              accountBalance = accountBalance - amount;
            else accountBalance = accountBalance + amount;

            db.collection("accounts")
              .doc(accountId)
              .update({
                account_balance: accountBalance,
              })
              .then(() => {
                //console.log("Account balance updated to " + accountBalance);
                SwalWithBootstrapButtons.fire(
                  "Deleted!",
                  "Transaction deleted successfully and updated account balance.",
                  "success"
                );
                loadTransactions();
              });
          } else {
            console.log("Account not found");
          }
        })
        .catch((error) => {
          console.log("Error getting document:", error);
        });
    } catch (err) {
      console.error(err);
    }
  }
  const handleShowReportsTrue = async () => {
    setShowReports(true);
    const transaction = {
      type: transType.current.value,
      customer: transCustomer.current.value,
      account: transBankAccount.current.value,
      category: transCategory.current.value,
      startDate: moment(startDate).startOf("day").toDate(),
      endDate: moment(endDate).endOf("day").toDate(),
    };
    let dbRefs = db.collection("transactions");
    // .where("account", "==", `${transaction.account}`)
    // .where("type", "==", `${transaction.type}`)
    // .where("category", "==", `${transaction.category}`)
    // .where("customer", "==", `${transaction.customer}`)
    // .where("date", ">=", transaction?.startDate)
    // .where("date", "<=", transaction?.endDate)
    // .orderBy("date", "desc");
    console.log(transaction);
    let typeRef = transaction?.type
      ? dbRefs.where("type", "==", `${transaction.type}`)
      : dbRefs;
    let customerRef = transaction?.customer
      ? typeRef.where("customer", "==", transaction.customer)
      : typeRef;
    let accountRef = transaction?.account
      ? customerRef.where("account", "==", transaction.account)
      : customerRef;
    let categoryRef = transaction?.category
      ? accountRef.where("category", "==", transaction.category)
      : accountRef;
    let startRef = transaction?.startDate
      ? categoryRef.where("date", ">=", transaction?.startDate)
      : categoryRef;
    let endRef = transaction?.endDate
      ? startRef.where("date", "<=", transaction?.endDate)
      : startRef;
    const data = await endRef.orderBy("date", "desc").get();
    let transactionsArr = [];
    for (var i = 0; i < data.docs.length; i++) {
      var transObj = data.docs[i].data();
      var docId = data.docs[i].id;
      const transaction = {
        id: docId,
        date: transObj.date.toDate().toLocaleString(),
        type: transObj.type,
        customer: transObj.customer,
        payment_method: transObj.payment_method,
        amount: transObj.amount,
        account: transObj.account,
        category: transObj.category,
        account_ref: transObj.account_ref,
        customer_ref: transObj.customer_ref,
      };
      transactionsArr.push(transaction);
    }

    setTransactions(transactionsArr);
    setFilteredTransactions(transactionsArr);
    let num = Math.ceil(transactionsArr.length / recordsPerPage);
    let pagiArr = [];
    for (let i = 1; i <= num; i++) {
      let item = { value: i, active: i === 1 ? true : false };
      pagiArr.push(item);
    }
    setCurrentAssetPage(1);
    setDisablePrev(true);
    if (pagiArr.length > 1) {
      setDisableNext(false);
    } else {
      setDisableNext(true);
    }
    setPaginationItems(pagiArr);
  };
  const getAllStatistics = async () => {
    setShowReports(true);
    let dbRefs = db.collection("transactions").orderBy("date", "desc");
    const data = await dbRefs.get();
    let transactionsArr = [];
    for (var i = 0; i < data.docs.length; i++) {
      var transObj = data.docs[i].data();
      var docId = data.docs[i].id;
      const transaction = {
        id: docId,
        date: transObj.date.toDate().toLocaleString(),
        type: transObj.type,
        customer: transObj.customer,
        payment_method: transObj.payment_method,
        amount: transObj.amount,
        account: transObj.account,
        category: transObj.category,
        account_ref: transObj.account_ref,
        customer_ref: transObj.customer_ref,
      };
      transactionsArr.push(transaction);
    }

    setFilteredTransactions(transactionsArr);
  };
  const handleShowReportsFalse = () => {
    setShowReports(false);
    setFilteredTransactions([]);
    const firstDate = moment()
      .startOf("month")
      .startOf("day")
      .format("YYYY-MM-DD");
    setStart(firstDate);
    const lastDate = moment().endOf("day").format("YYYY-MM-DD");
    setEnd(lastDate);
    transCategory.current.value = "";
    transCustomer.current.value = "";
    transType.current.value = "";
    transBankAccount.current.value = "";
  };
  async function setAccountIdByAccountName() {
    //console.log("setAccountIdByAccountName: " + transBankAccount.current.value);
    await accounts.map((account) => {
      //console.log(account.id + " => " + account.name + " => " + transBankAccount.current.value);
      if (account.name === transBankAccount.current.value) {
        setSelectedAccountId(account.id);
      }
    });
  }
  useEffect(() => {
    if (filteredTransactions.length > 0) {
      const sumAll = filteredTransactions
        .map((item) => item.amount)
        .reduce((prev, curr) => Number(prev) + Number(curr), 0);
      setSumAmount(sumAll);
    }
  }, [filteredTransactions]);
  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <h4>Filtered Reports</h4>
        </div>
      </div>
      <Row>
        {showReports ? (
          <>
            {[{ name: "Total Amount", balance: 500 }].map((account) => (
              <Col xs={12} sm={6} xl={4} className="mb-4">
                <AccountsWidget
                  category={account.name}
                  title={"$" + parseFloat(sumAmount).toFixed(2)}
                  icon={faChartLine}
                  iconColor="shape-secondary"
                  showAccBalText={true}
                />
              </Col>
            ))}
          </>
        ) : (
          ""
        )}
      </Row>

      <div className="table-settings mb-4">
        <Row className="align-items-center mb-3">
          <Col xs={3} md={3} lg={3} xl={3}>
            <Form.Group id="date">
              <Form.Label>Start Date</Form.Label>
              <Datetime
                timeFormat={false}
                closeOnSelect={true}
                onChange={setStart}
                value={moment(startDate).endOf("day").toDate()}
                renderInput={(props, openCalendar) => (
                  <InputGroup>
                    <InputGroup.Text>
                      <FontAwesomeIcon icon={faCalendarAlt} />
                    </InputGroup.Text>
                    <Form.Control
                      required
                      type="text"
                      placeholder="YYYY-MM-DD"
                      value={startDate}
                      onFocus={openCalendar}
                      onChange={() => {}}
                    />
                  </InputGroup>
                )}
              />
            </Form.Group>
          </Col>
          <Col xs={3} md={3} lg={3} xl={3}>
            <Form.Group id="date">
              <Form.Label>End Date</Form.Label>
              <Datetime
                timeFormat={false}
                closeOnSelect={true}
                onChange={setEnd}
                visible={false}
                value={moment(endDate).endOf("day").toDate()}
                isValidDate={(currDate) =>
                  currDate.isAfter(moment(start).subtract(1, "days").toDate())
                }
                renderInput={(props, openCalendar) => (
                  <InputGroup>
                    <InputGroup.Text>
                      <FontAwesomeIcon icon={faCalendarAlt} />
                    </InputGroup.Text>
                    <Form.Control
                      required
                      type="text"
                      placeholder="YYYY-MM-DD"
                      value={endDate}
                      onFocus={openCalendar}
                      onChange={() => {}}
                    />
                  </InputGroup>
                )}
              />
            </Form.Group>
          </Col>
          <Col xs={3} md={3} lg={3} xl={3}>
            <Form.Group id="category">
              <Form.Label>Category</Form.Label>
              <Form.Select ref={transCategory}>
                <option value="">All</option>
                {transactionCategories.map((category) => (
                  <option value={category.title}>{category.title}</option>
                ))}
              </Form.Select>
            </Form.Group>
          </Col>
        </Row>
        <Row className="align-items-center">
          <Col xs={3} md={3} lg={3} xl={3}>
            <Form.Group id="type">
              <Form.Label>Type</Form.Label>
              <Form.Select ref={transType}>
                <option value="">All</option>
                <option value="Income">Income</option>
                <option value="Expense">Expense</option>
              </Form.Select>
            </Form.Group>
          </Col>
          <Col xs={3} md={3} lg={3} xl={3}>
            <Form.Group id="customer">
              <Form.Label>Customer</Form.Label>
              <Form.Select ref={transCustomer}>
                <option value="">All</option>
                {customers.map((customer) => (
                  <option value={customer.name}>{customer.name}</option>
                ))}
              </Form.Select>
            </Form.Group>
          </Col>
          <Col xs={3} md={3} lg={3} xl={3}>
            <Form.Group id="account">
              <Form.Label>Bank Account</Form.Label>
              <Form.Select
                ref={transBankAccount}
                onChange={setAccountIdByAccountName}
              >
                <option value="">All</option>
                {accounts.map((account) => (
                  <option value={account.name}>{account.name}</option>
                ))}
              </Form.Select>
            </Form.Group>
          </Col>
        </Row>
        <Row className="justify-content-end align-items-center mt-3">
          <Col xs={6} md={6} lg={6} xl={6}>
            <Button className="btn btn-primary" onClick={handleShowReportsTrue}>
              Search
            </Button>
            <Button
              variant="danger"
              className="mx-2"
              onClick={handleShowReportsFalse}
            >
              Reset
            </Button>
            {/* <Button
              className="btn btn-secondary mx-2"
              onClick={getAllStatistics}
            >
              Get All Statistics
            </Button> */}
          </Col>
        </Row>
      </div>
      {showReports ? (
        <Card
          border="light"
          className="table-wrapper table-responsive shadow-sm"
        >
          <Card.Body className="pt-0">
            {loading ? (
              <Loading />
            ) : (
              <>
                <Table hover className="user-table align-items-center">
                  <thead>
                    <tr>
                      <th className="border-bottom">Date</th>
                      <th className="border-bottom">Type</th>
                      <th className="border-bottom">Customer</th>
                      <th className="border-bottom">Account</th>
                      <th className="border-bottom">Amount</th>
                      <th className="border-bottom">Category</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredTransactions.length > 0 ? (
                      filteredTransactions
                        .slice(startAssetRecord - 1, endAssetRecord)
                        .map((transaction, index) => (
                          <tr key={index}>
                            <td>
                              <span className="fw-normal">
                                {moment(transaction.date).format("MM/DD/YYYY")}
                              </span>
                            </td>
                            <td>
                              <span className="fw-normal">
                                {transaction.type}
                              </span>
                            </td>
                            <td>
                              <span className="fw-normal">
                                {transaction.customer}
                              </span>
                            </td>
                            <td>
                              <span className="fw-normal">
                                {transaction.account}
                              </span>
                            </td>
                            <td>
                              <span className="fw-normal">
                                ${transaction.amount}
                              </span>
                            </td>
                            <td>
                              <span className="fw-normal">
                                {transaction.category}
                              </span>
                            </td>
                          </tr>
                        ))
                    ) : (
                      <tr>
                        <td colSpan={6}>
                          No Transactions Available with the above filter data
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
                <Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between">
                  <Pagination className="mb-2 mb-lg-0">
                    <Pagination.Prev
                      disabled={disablePrev}
                      onClick={handlePrev}
                    >
                      Previous
                    </Pagination.Prev>
                    {paginationElements.length > 0 &&
                      paginationElements.map((item, index) => {
                        return (
                          <>
                            <Pagination.Item
                              value={item.value}
                              key={item.value}
                              onClick={(e) => handlePageChange(item, index)}
                              active={item.active}
                            >
                              {item.value}
                            </Pagination.Item>
                          </>
                        );
                      })}
                    <Pagination.Next
                      disabled={disableNext}
                      onClick={handleNext}
                    >
                      Next
                    </Pagination.Next>
                  </Pagination>
                  {filteredTransactions.length > 0 && (
                    <small className="fw-bold">
                      Showing <b>{startAssetRecord}</b>
                      {" - "}
                      {endAssetRecord >= filteredTransactions.length && (
                        <b>{filteredTransactions.length}</b>
                      )}
                      {endAssetRecord < filteredTransactions.length && (
                        <b>{endAssetRecord}</b>
                      )}{" "}
                      of <b>{filteredTransactions.length}</b>
                    </small>
                  )}
                </Card.Footer>
              </>
            )}
          </Card.Body>
        </Card>
      ) : (
        ""
      )}
    </>
  );
}

export default Reports;
