import { Image } from '@themesberg/react-bootstrap';
import React from 'react';
import LoadingIcon from "../../assets/img/loading.gif";

const Loading = () => {
    return (
        <div className="loading_icon">
            <Image src={LoadingIcon} />
        </div>
    );
};

export default Loading;