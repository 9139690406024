/* eslint-disable array-callback-return */
import React, { useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faCog,
  faPlus,
  faSearch,
  faEdit,
  faEllipsisH,
  faEye,
  faTrashAlt,
  faSave,
  faBackward,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import {
  Card,
  Table,
  Nav,
  Pagination,
  Col,
  Row,
  Form,
  Button,
  ButtonGroup,
  InputGroup,
  Dropdown,
  OverlayTrigger,
  Tooltip,
} from "@themesberg/react-bootstrap";
import { db } from "../firebase";
import Modal from "react-modal";
import "../scss/style.css";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import Datetime from "react-datetime";
import moment from "moment-timezone";
import accounts from "../data/accounts";
import paymentMethods from "../data/paymentMethods";
import Loader from "react-loader-spinner";
import { AddNewTransactionModal } from "../components/AddNewTransactionModal";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Loading from "./components/Loading";
import { UpdateTransactionModal } from "../components/UpdateTransactionModel";

function Transactions() {
  const defaultModalProps = { id: "", title: "", start: null, end: null };
  const [modalProps, setModalProps] = useState(defaultModalProps);
  const [updatemodalProps, setUpdateModalProps] = useState(defaultModalProps);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const SwalWithBootstrapButtons = withReactContent(
    Swal.mixin({
      customClass: {
        confirmButton: "btn btn-primary me-3",
        cancelButton: "btn btn-gray",
      },
      buttonsStyling: true,
    })
  );

  //const [transactions, setTransactions] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const [filteredTransactions, setFilteredTransactions] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [totalItemCount, setTotalItemCount] = useState(1);
  const [activePage, setActivePage] = useState(1);

  const [transactionDate, setTransactionDate] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [reimbursedRowClass, setReimbursedRowClass] = useState("d-sm-none");
  const [selectedAccountId, setSelectedAccountId] = useState("");

  const transType = useRef("Income");
  const transAmount = useRef(0);

  const [transFilterString, setTransFilterString] = useState("");
  //const onTransFilterStringChange = (e) => setTransFilterString(e.target.value);
  const [refreshTransactions, setRefreshTransactions] = useState(false);
  const [recordsPerPage, setRecordsPerPage] = useState(10);
  const dropdown = [
    { name: 10, checked: true },
    { name: 20, checked: false },
    { name: 30, checked: false },
  ];
  const [paginationElements, setPaginationItems] = useState([]);
  const [dropdownItems, setDropDownItems] = useState(dropdown);
  const [currentAssetPage, setCurrentAssetPage] = useState(0);
  const [assetPageSize, setAssetPageSize] = useState(1);
  const [startAssetRecord, setStartAssetRecord] = useState(1);
  const [endAssetRecord, setEndAssetRecord] = useState(recordsPerPage);
  const [totalAssetCount, setTotalAssetCount] = useState(0);
  const [disablePrev, setDisablePrev] = useState(false);
  const [disableNext, setDisableNext] = useState(false);
  const [editTransactionObj, setEditTransactionObj] = useState({});

  const handleSelectRecordsPerPage = (item) => {
    let transactionsArr = [...filteredTransactions];
    let num = Math.ceil(transactionsArr.length / item.name);
    let pagiArr = [];
    for (let i = 1; i <= num; i++) {
      let item = { value: i, active: i === 1 ? true : false };
      pagiArr.push(item);
    }
    setCurrentAssetPage(1);
    setDisablePrev(true);
    if (pagiArr.length > 1) {
      setDisableNext(false);
    } else {
      setDisableNext(true);
    }
    setPaginationItems(pagiArr);
    setRecordsPerPage(item.name);
    setStartAssetRecord(1);
    setEndAssetRecord(item.name);
    let arr = [...dropdown];
    let paginArr = [];
    arr.map((drop, i) => {
      let a = {
        name: drop.name,
        checked: drop.name === item.name ? true : false,
      };
      paginArr.push(a);
    });
    setDropDownItems(paginArr);
  };

  function onTransFilterStringChange(e) {
    setLoading(true);
    //console.log("onTransFilterStringChange: " + e.target.value);
    setTransFilterString(e.target.value);

    var tempTransactions = [];
    var keyword = e.target.value.toUpperCase();
    var index = 0;
    transactions.map((transaction) => {
      //console.log("onTransFilterStringChange > " + keyword + " => " + transaction.type.toUpperCase());
      if (
        transaction.type.toUpperCase().includes(keyword) ||
        transaction.category.toUpperCase().includes(keyword) ||
        transaction.account.toUpperCase().includes(keyword) ||
        transaction.customer.toUpperCase().includes(keyword)
      ) {
        tempTransactions[index] = transaction;
        index = index + 1;
      }
    });
    setFilteredTransactions(tempTransactions);
    setLoading(false);
    //console.log("filteredTransactions => " + filteredTransactions.length);
  }

  async function onTransactionDelete(id) {
    if (id !== undefined) {
      const result = await SwalWithBootstrapButtons.fire({
        icon: "error",
        title: "Confirm deletion!",
        text: "Are you sure you want to delete this Transaction?",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel!",
      });

      //setShowEditModal(false);
      //setModalProps(defaultModalProps);

      if (result.isConfirmed) {
        try {
          var transactionObj;
          await db
            .collection("transactions")
            .doc(id)
            .get()
            .then((doc) => {
              transactionObj = doc.data();
            });

          await db
            .collection("transactions")
            .doc(id)
            .delete()
            .then(() => {
              //loadTransactions();
              console.log("The transaction has been deleted.");
              updateAccountBalance(transactionObj, id);
              //SwalWithBootstrapButtons.fire('Deleted!', 'The transaction has been deleted.', 'success');
            })
            .catch((error) => {
              console.log(error);
              SwalWithBootstrapButtons.fire(
                "Not deleted!",
                "Unable to delete the transaction",
                "warning"
              );
            });
        } catch (err) {
          console.log(err);
          await SwalWithBootstrapButtons.fire(
            "Failed!",
            "Unable to delete the transaction",
            "warning"
          );
        }
      }
    }
  }
  async function loadTransactions() {
    setLoading(true);

    try {
      for (var i = 0; i < transactions.length; i++) {
        transactions.pop(i);
      }

      const data = await db
        .collection("transactions")
        .orderBy("date", "desc")
        .get();
      //console.log("data.docs.length" + data.docs.length);
      let transactionsArr = [];
      for (var i = 0; i < data.docs.length; i++) {
        var transObj = data.docs[i].data();
        var docId = data.docs[i].id;
        const transaction = {
          id: docId,
          date: transObj?.date.toDate().toLocaleString(),
          type: transObj?.type,
          customer: transObj?.customer,
          payment_method: transObj?.payment_method,
          amount: transObj?.amount,
          account: transObj?.account,
          category: transObj?.category,
          account_ref: transObj?.account_ref,
          customer_ref: transObj?.customer_ref,
          updateDate: transObj?.date,
          notes: transObj?.notes,
        };
        transactionsArr.push(transaction);
        setTransactions(transactionsArr);
        setFilteredTransactions(transactionsArr);
        let num = Math.ceil(transactionsArr.length / recordsPerPage);
        let pagiArr = [];
        for (let i = 1; i <= num; i++) {
          let item = { value: i, active: i === 1 ? true : false };
          pagiArr.push(item);
        }
        setCurrentAssetPage(1);
        setDisablePrev(true);
        if (pagiArr.length > 1) {
          setDisableNext(false);
        } else {
          setDisableNext(true);
        }
        setPaginationItems(pagiArr);
      }

      setLoading(false);
    } catch (err) {
      console.log("Error while retrieving transactions: " + err);
    }
  }

  function handlePageChange(e, index) {
    setCurrentAssetPage(e.value);
    setStartAssetRecord((e.value - 1) * recordsPerPage + 1);
    setEndAssetRecord(e.value * recordsPerPage);
    let arr = [...paginationElements];
    arr.forEach((item) => {
      if (item.value === e.value) {
        item.active = true;
      } else {
        item.active = false;
      }
    });
    setPaginationItems(arr);
    if (e.value === arr.length) {
      setDisableNext(true);
    } else {
      setDisableNext(false);
    }
    if (e.value === 1) {
      setDisablePrev(true);
    } else {
      setDisablePrev(false);
    }
  }
  const handlePrev = () => {
    setCurrentAssetPage(currentAssetPage - 1);
    setStartAssetRecord((currentAssetPage - 1 - 1) * recordsPerPage + 1);
    setEndAssetRecord((currentAssetPage - 1) * recordsPerPage);
    let arr = [...paginationElements];
    arr.forEach((item) => {
      if (item.value === currentAssetPage - 1) {
        item.active = true;
      } else {
        item.active = false;
      }
    });
    setPaginationItems(arr);
    if (currentAssetPage - 1 === arr.length) {
      setDisableNext(true);
    } else {
      setDisableNext(false);
    }
    if (currentAssetPage - 1 === 1) {
      setDisablePrev(true);
    } else {
      setDisablePrev(false);
    }
  };
  const handleNext = () => {
    setCurrentAssetPage(currentAssetPage + 1);
    setStartAssetRecord((currentAssetPage + 1 - 1) * recordsPerPage + 1);
    setEndAssetRecord((currentAssetPage + 1) * recordsPerPage);
    let arr = [...paginationElements];
    arr.forEach((item) => {
      if (item.value === currentAssetPage + 1) {
        item.active = true;
      } else {
        item.active = false;
      }
    });
    setPaginationItems(arr);
    if (currentAssetPage + 1 === arr.length) {
      setDisableNext(true);
    } else {
      setDisableNext(false);
    }
    if (currentAssetPage + 1 === 1) {
      setDisablePrev(true);
    } else {
      setDisablePrev(false);
    }
  };

  function toggleModal() {
    //setIsOpen(!isOpen);
    setShowAddModal(!isOpen);
  }

  const toggleUpdateModal = (item) => {
    setEditTransactionObj(item);
    setShowUpdateModal(true);
  };

  const handleClose = () => {
    setShowAddModal(false);
    setRefreshTransactions(!refreshTransactions);
  };
  const handleCloseUpdate = () => {
    setShowUpdateModal(false);
    setRefreshTransactions(!refreshTransactions);
  };

  useEffect(() => {
    loadTransactions();
  }, [refreshTransactions]);

  async function updateAccountBalance(transactionObj, transactionId) {
    try {
      const transactionType = transactionObj.type;
      const transactionAccount = transactionObj.account;
      const transactionAmount = transactionObj.amount;
      var accountId;
      accounts.map((account) => {
        //console.log(account.id + " => " + account.name + " => " + transBankAccount.current.value);
        if (account.name === transactionAccount) {
          accountId = account.id;
          //console.log("account.id: " + account.id + ", selectedAccountId: " + accountId);
        }
      });

      var accountRef = db.collection("accounts").doc(accountId);
      accountRef
        .get()
        .then((doc) => {
          if (doc.exists) {
            //console.log("Document data:", doc.data());
            const accountDetails = doc.data();
            //console.log("Account name: " + accountDetails.account_name);
            //console.log("Account balance: " + accountDetails.account_balance);

            var accountBalance = Number(accountDetails.account_balance);
            const amount = Number(transactionAmount);

            //console.log("Amount: " + amount);
            //console.log("accountBalance: " + accountBalance);
            if (transactionType === "Income")
              accountBalance = accountBalance - amount;
            else accountBalance = accountBalance + amount;

            db.collection("accounts")
              .doc(accountId)
              .update({
                account_balance: accountBalance,
              })
              .then(() => {
                //console.log("Account balance updated to " + accountBalance);
                SwalWithBootstrapButtons.fire(
                  "Deleted!",
                  "Transaction deleted successfully and updated account balance.",
                  "success"
                );
                loadTransactions();
              });
          } else {
            console.log("Account not found");
          }
        })
        .catch((error) => {
          console.log("Error getting document:", error);
        });
    } catch (err) {
      console.error(err);
    }
  }

  const onTransactionAdd = (props) => {
    const newEvent = {
      ...props,
      dragable: true,
      className: "bg-blue text-white",
      allDay: true,
    };
    loadTransactions();
    setShowAddModal(false);
    //setEvents([...events, newEvent]);
    setModalProps(defaultModalProps);
  };
  const onTransactionUpdate = (props) => {
    loadTransactions();
    setShowUpdateModal(false);
    //setEvents([...events, newEvent]);
    setUpdateModalProps(defaultModalProps);
  };

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <h4>Transactions</h4>
          <p className="mb-0">
            Accounting transactions for Income and Expenses
          </p>
        </div>
        <div className="btn-toolbar mb-2 mb-md-0">
          <ButtonGroup>
            <Button variant="primary" size="sm" onClick={toggleModal}>
              <FontAwesomeIcon icon={faPlus} className="me-2" /> Add New
            </Button>
          </ButtonGroup>

          {/* START: Add New Transaction Modal */}

          <AddNewTransactionModal
            {...modalProps}
            show={showAddModal}
            onAdd={onTransactionAdd}
            onHide={handleClose}
          />
          <UpdateTransactionModal
            {...modalProps}
            show={showUpdateModal}
            onAdd={onTransactionUpdate}
            onHide={handleCloseUpdate}
            editTransactionObj={editTransactionObj}
          />
        </div>
      </div>

      <div className="table-settings mb-4">
        <Row className="justify-content-between align-items-center">
          <Col xs={8} md={6} lg={3} xl={4}>
            <InputGroup>
              <InputGroup.Text>
                <FontAwesomeIcon icon={faSearch} />
              </InputGroup.Text>
              <Form.Control
                type="text"
                placeholder="Search transaction"
                onKeyUp={onTransFilterStringChange}
              />
            </InputGroup>
          </Col>
          <Col xs={4} md={2} xl={1} className="ps-md-0 text-end">
            <Dropdown as={ButtonGroup}>
              <Dropdown.Toggle
                split
                as={Button}
                variant="link"
                className="text-dark m-0 p-0"
              >
                <span className="icon icon-sm icon-gray">
                  <FontAwesomeIcon icon={faCog} />
                </span>
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-menu-xs dropdown-menu-right">
                <Dropdown.Item className="fw-bold text-dark">
                  Show
                </Dropdown.Item>
                {dropdownItems.map((val) => {
                  if (val.checked) {
                    return (
                      <Dropdown.Item
                        className="d-flex fw-bold"
                        onClick={() => handleSelectRecordsPerPage(val)}
                      >
                        {val.name}{" "}
                        <span className="icon icon-small ms-auto">
                          <FontAwesomeIcon icon={faCheck} />
                        </span>
                      </Dropdown.Item>
                    );
                  } else {
                    return (
                      <Dropdown.Item
                        className="fw-bold"
                        onClick={() => handleSelectRecordsPerPage(val)}
                      >
                        {val.name}
                      </Dropdown.Item>
                    );
                  }
                })}
                {/* <Dropdown.Item className="d-flex fw-bold">
                  10{" "}
                  <span className="icon icon-small ms-auto">
                    <FontAwesomeIcon icon={faCheck} />
                  </span>
                </Dropdown.Item>
                <Dropdown.Item className="fw-bold">20</Dropdown.Item>
                <Dropdown.Item className="fw-bold">30</Dropdown.Item> */}
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        </Row>
      </div>

      {/* <TransactionsTable /> */}

      <Card border="light" className="table-wrapper table-responsive shadow-sm">
        <Card.Body className="pt-0">
          {loading ? (
            <Loading />
          ) : (
            <>
              <Table hover className="user-table align-items-center">
                <thead>
                  <tr>
                    <th className="border-bottom">Date</th>
                    <th className="border-bottom">Type</th>
                    <th className="border-bottom">Customer</th>
                    <th className="border-bottom">Account</th>
                    <th className="border-bottom">Amount</th>
                    <th className="border-bottom">Category</th>
                    <th className="border-bottom">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredTransactions
                    .slice(startAssetRecord - 1, endAssetRecord)
                    .map((transaction, index) => (
                      <tr key={index}>
                        <td>
                          <span className="fw-normal">
                            {moment(transaction.date).format("MM/DD/YYYY")}
                          </span>
                        </td>
                        <td>
                          <span className="fw-normal">{transaction.type}</span>
                        </td>
                        <td>
                          <span className="fw-normal">
                            {transaction.customer}
                          </span>
                        </td>
                        <td>
                          <span className="fw-normal">
                            {transaction.account}
                          </span>
                        </td>
                        <td>
                          <span className="fw-normal">
                            ${transaction.amount}
                          </span>
                        </td>
                        <td>
                          <span className="fw-normal">
                            {transaction.category}
                          </span>
                        </td>
                        <td>
                            {/*
                          <OverlayTrigger
                            trigger={["hover", "focus"]}
                            placement="top"
                            overlay={<Tooltip>Edit</Tooltip>}
                          >
                          <Card.Link className="text-info ms-2 cursor-pointer">
                              <FontAwesomeIcon
                                icon={faEdit}
                                onClick={() => toggleUpdateModal(transaction)}
                              />
                            </Card.Link>
                          </OverlayTrigger>
                          */}

                          <OverlayTrigger
                            trigger={["hover", "focus"]}
                            placement="top"
                            overlay={<Tooltip>Delete</Tooltip>}
                          >
                            <Card.Link className="text-danger ms-2 cursor-pointer">
                              <FontAwesomeIcon
                                icon={faTrashAlt}
                                onClick={() =>
                                  onTransactionDelete(transaction.id)
                                }
                              />
                            </Card.Link>
                          </OverlayTrigger>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
              <Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between">
                <Pagination className="mb-2 mb-lg-0">
                  <Pagination.Prev disabled={disablePrev} onClick={handlePrev}>
                    Previous
                  </Pagination.Prev>
                  {paginationElements.length > 0 &&
                    paginationElements.map((item, index) => {
                      return (
                        <>
                          <Pagination.Item
                            value={item.value}
                            key={item.value}
                            onClick={(e) => handlePageChange(item, index)}
                            active={item.active}
                          >
                            {item.value}
                          </Pagination.Item>
                        </>
                      );
                    })}
                  <Pagination.Next disabled={disableNext} onClick={handleNext}>
                    Next
                  </Pagination.Next>
                </Pagination>
                {filteredTransactions.length > 0 && (
                  <small className="fw-bold">
                    Showing <b>{startAssetRecord}</b>
                    {" - "}
                    {endAssetRecord >= filteredTransactions.length && (
                      <b>{filteredTransactions.length}</b>
                    )}
                    {endAssetRecord < filteredTransactions.length && (
                      <b>{endAssetRecord}</b>
                    )}{" "}
                    of <b>{filteredTransactions.length}</b>
                  </small>
                )}
              </Card.Footer>
            </>
          )}
        </Card.Body>
      </Card>
    </>
  );
}

export default Transactions;
