import React, { useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faCog,
  faPlus,
  faSearch,
  faEdit,
  faEllipsisH,
  faEye,
  faTrashAlt,
  faSave,
  faBackward,
  faDollarSign,
  faPaperPlane,
} from "@fortawesome/free-solid-svg-icons";
import {
  Card,
  Table,
  Nav,
  Pagination,
  Col,
  Row,
  Form,
  Button,
  ButtonGroup,
  InputGroup,
  Dropdown,
  OverlayTrigger,
  Tooltip,
} from "@themesberg/react-bootstrap";
import { db } from "../firebase";
import "../scss/style.css";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import Datetime from "react-datetime";
import moment from "moment-timezone";
import accounts from "../data/accounts";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Collapsible from "react-collapsible";
import Loader from "react-loader-spinner";
import { Spinner } from "./components/spinner";
import Loading from "./components/Loading";
import { AddNewTransferModal } from "../components/AddTransferModal";

function Transfers() {
  const SwalWithBootstrapButtons = withReactContent(
    Swal.mixin({
      customClass: {
        confirmButton: "btn btn-primary me-3",
        cancelButton: "btn btn-gray",
      },
      buttonsStyling: true,
    })
  );

  //const [transactions, setTransactions] = useState([]);
  const [transfers, setTransfers] = useState([]);
  const [filteredTransfers, setFilteredTransfers] = useState([]);
  const [itemsPerPage] = useState(10);
  const [totalItemCount] = useState(100);
  const [activePage, setActivePage] = useState(1);

  const [loading, setLoading] = useState(true);
  const [selectedFromtId, setSelectedFromAccountId] = useState("");
  const [selectedToId, setSelectedToId] = useState("");

  const transAmount = useRef(0);
  const transDate = useRef("");
  const fromBankAccount = useRef("");
  const toBankAccount = useRef("");
  const transNotes = useRef("");
  const [transactionDate, setTransactionDate] = useState(
    moment().format("MM/DD/YYYY")
  );
  const [recordsPerPage, setRecordsPerPage] = useState(10);
  const dropdown = [
    { name: 10, checked: true },
    { name: 20, checked: false },
    { name: 30, checked: false },
  ];
  const [startAssetRecord, setStartAssetRecord] = useState(1);
  const [endAssetRecord, setEndAssetRecord] = useState(recordsPerPage);
  const [paginationElements, setPaginationItems] = useState([]);
  const [dropdownItems, setDropDownItems] = useState(dropdown);
  const [currentAssetPage, setCurrentAssetPage] = useState(0);
  const [showAddModal, setShowAddModal] = useState(false);
  const [disablePrev, setDisablePrev] = useState(false);
  const [disableNext, setDisableNext] = useState(false);
  const [transFilterString, setTransFilterString] = useState("");
  const [refreshTransfers, setRefreshTransfers] = useState(false);
  const [accountsList, setAccountsList] = useState([]);
  const [editTransfer, setEditTransfer] = useState(false);
  const [editTransferObj, setEditTransferObj] = useState({});

  function toggleModal() {
    setShowAddModal(true);
  }
  const handleClose = () => {
    setShowAddModal(false);
    setEditTransfer(false);
    setEditTransferObj({});
  };
  async function setAccountIdByAccountName(type) {
    await accounts.map((account) => {
      if (type === "from") {
        if (account.name === fromBankAccount.current.value) {
          setSelectedFromAccountId(account.id);
          console.log(
            "account.id: " +
              account.id +
              ", selectedFromtId: " +
              selectedFromtId
          );
        }
      } else {
        if (account.name === toBankAccount.current.value) {
          setSelectedToId(account.id);
          console.log(
            "account.id: " + account.id + ", selectedToId: " + selectedToId
          );
        }
      }
    });
  }
  function handlePageChange(e, index) {
    setCurrentAssetPage(e.value);
    setStartAssetRecord((e.value - 1) * recordsPerPage + 1);
    setEndAssetRecord(e.value * recordsPerPage);
    let arr = [...paginationElements];
    arr.forEach((item) => {
      if (item.value === e.value) {
        item.active = true;
      } else {
        item.active = false;
      }
    });
    setPaginationItems(arr);
    if (e.value === arr.length) {
      setDisableNext(true);
    } else {
      setDisableNext(false);
    }
    if (e.value === 1) {
      setDisablePrev(true);
    } else {
      setDisablePrev(false);
    }
  }

  useEffect(() => {
    loadAccounts();
    loadTransfers();
  }, [refreshTransfers]);
  const loadAccounts = async () => {
    setLoading(true);
    try {
      const data = await db.collection("accounts").get();
      let accArr = [];
      for (var i = 0; i < data.docs.length; i++) {
        var accObj = data.docs[i].data();
        var docId = data.docs[i].id;
        console.log(accObj);
        const acc = {
          id: docId,
          name: accObj?.account_name,
          sl: i + 1,
        };
        accArr.push(acc);
      }
      setAccountsList(accArr);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };
  const loadTransfers = async () => {
    setLoading(true);
    try {
      const data = await db
        .collection("transfers")
        .orderBy("date", "desc")
        .get();
      let transfersArr = [];
      for (var i = 0; i < data.docs.length; i++) {
        var transObj = data.docs[i].data();
        var docId = data.docs[i].id;
        const transaction = {
          id: docId,
          date: transObj.date.toDate().toLocaleString(),
          from_account: transObj.from_account,
          to_account: transObj.to_account,
          amount: transObj.amount,
          notes: transObj.notes,
          from_account_id: transObj?.from_account_id,
          to_account_id: transObj?.to_account_id,
        };
        transfersArr.push(transaction);
      }
      setTransfers(transfersArr);
      setFilteredTransfers(transfersArr);
      let num = Math.ceil(transfersArr.length / recordsPerPage);
      let pagiArr = [];
      for (let i = 1; i <= num; i++) {
        let item = { value: i, active: i === 1 ? true : false };
        pagiArr.push(item);
      }
      setCurrentAssetPage(1);
      setDisablePrev(true);
      if (pagiArr.length > 1) {
        setDisableNext(false);
      } else {
        setDisableNext(true);
      }
      setPaginationItems(pagiArr);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  const showConfirmationDialog = async (message) => {
    const result = await SwalWithBootstrapButtons.fire({
      icon: "info",
      title: message,
      text: "",
      showCancelButton: false,
      okayButtonText: "Okay",
    });

    if (result.isConfirmed) {
      setRefreshTransfers(!refreshTransfers);
    }
  };

  async function performNewTransfer() {
    console.log("performNewTransfer");
    if (
      transactionDate === "" ||
      transAmount.current.value === "" ||
      transAmount.current.value === 0
    ) {
      await SwalWithBootstrapButtons.fire(
        "Fields empty!",
        "Transfer date and amount is mandatory!",
        "warning"
      );
    } else {
      var fromAccountId;
      accounts.map((account) => {
        if (account.name === fromBankAccount.current.value) {
          fromAccountId = account.id;
          console.log(
            "fromBankAccount.id: " +
              account.id +
              ", selectedAccountId: " +
              fromAccountId
          );
        }
      });

      var toAccountId;
      accounts.map((account) => {
        if (account.name === toBankAccount.current.value) {
          toAccountId = account.id;
          console.log(
            "toBankAccount.id: " +
              account.id +
              ", selectedAccountId: " +
              toAccountId
          );
        }
      });

      console.log(
        "Transfer account id: " + fromAccountId + " => " + toAccountId
      );
      const transfer = {
        date: new Date(transactionDate),
        from_account: fromBankAccount.current.value,
        to_account: toBankAccount.current.value,
        amount: transAmount.current.value,
        notes: transNotes.current.value,
        from_account_id: fromAccountId,
        to_account_id: toAccountId,
      };

      try {
        await db.collection("transfers").add(transfer);
        console.log("Transfer saved successfully.");
        setShowAddModal(false);
        updateAccountBalance(
          fromAccountId,
          toAccountId,
          Number(transAmount.current.value)
        );
      } catch (err) {
        console.error(err);
        alert(err.message);
      }
    }
  }
  async function performUpdateTransfer() {
    console.log("performNewTransfer");
    if (
      transactionDate === "" ||
      transAmount.current.value === "" ||
      transAmount.current.value === 0
    ) {
      await SwalWithBootstrapButtons.fire(
        "Fields empty!",
        "Transfer date and amount is mandatory!",
        "warning"
      );
    } else {
      var fromAccountId;
      accounts.map((account) => {
        if (account.name === fromBankAccount.current.value) {
          fromAccountId = account.id;
          console.log(
            "fromBankAccount.id: " +
              account.id +
              ", selectedAccountId: " +
              fromAccountId
          );
        }
      });

      var toAccountId;
      accounts.map((account) => {
        if (account.name === toBankAccount.current.value) {
          toAccountId = account.id;
          console.log(
            "toBankAccount.id: " +
              account.id +
              ", selectedAccountId: " +
              toAccountId
          );
        }
      });
      const transfer = {
        date: new Date(transactionDate),
        from_account: fromBankAccount.current.value,
        to_account: toBankAccount.current.value,
        amount: transAmount.current.value,
        notes: transNotes.current.value,
        from_account_id: fromAccountId,
        to_account_id: toAccountId,
      };

      try {
        await db
          .collection("transfers")
          .doc(editTransferObj.id)
          .update(transfer);
        setShowAddModal(false);
        updateAccountBalance(
          fromAccountId,
          toAccountId,
          Number(transAmount.current.value)
        );
      } catch (err) {
        console.error(err);
        alert(err.message);
      }
    }
  }

  async function updateAccountBalance(fromAccountId, toAccountId, amount) {
    console.log(
      "updateAccountBalance: " +
        fromAccountId +
        " => " +
        toAccountId +
        ", amount: " +
        amount
    );
    try {
      var accountRef = db.collection("accounts").doc(fromAccountId);
      accountRef
        .get()
        .then((doc) => {
          if (doc.exists) {
            console.log("From account data:", doc.data());
            const accountDetails = doc.data();
            var accountBalance = Number(accountDetails.account_balance);
            accountBalance = accountBalance - amount;
            db.collection("accounts")
              .doc(fromAccountId)
              .update({
                account_balance: accountBalance,
              })
              .then(() => {
                console.log("Account balance updated in from account");

                accountRef = db.collection("accounts").doc(toAccountId);
                accountRef
                  .get()
                  .then((doc) => {
                    if (doc.exists) {
                      console.log("From account data:", doc.data());
                      const accountDetails = doc.data();
                      accountBalance = Number(accountDetails.account_balance);
                      accountBalance = accountBalance + amount;

                      db.collection("accounts")
                        .doc(toAccountId)
                        .update({
                          account_balance: accountBalance,
                        })
                        .then(() => {
                          console.log("Account balance updated in to account");
                          loadTransfers();
                          showConfirmationDialog("Transfer done successfully!");
                        });
                    } else {
                      console.log("To account not found");
                    }
                  })
                  .catch((error) => {
                    console.log("Error getting document:", error);
                  });
              });
          } else {
            console.log("From account not found");
          }
        })
        .catch((error) => {
          console.log("Error getting document:", error);
        });
    } catch (err) {
      console.error(err);
    }
  }
  const handlePrev = () => {
    setCurrentAssetPage(currentAssetPage - 1);
    setStartAssetRecord((currentAssetPage - 1 - 1) * recordsPerPage + 1);
    setEndAssetRecord((currentAssetPage - 1) * recordsPerPage);
    let arr = [...paginationElements];
    arr.forEach((item) => {
      if (item.value === currentAssetPage - 1) {
        item.active = true;
      } else {
        item.active = false;
      }
    });
    setPaginationItems(arr);
    if (currentAssetPage - 1 === arr.length) {
      setDisableNext(true);
    } else {
      setDisableNext(false);
    }
    if (currentAssetPage - 1 === 1) {
      setDisablePrev(true);
    } else {
      setDisablePrev(false);
    }
  };
  const handleNext = () => {
    setCurrentAssetPage(currentAssetPage + 1);
    setStartAssetRecord((currentAssetPage + 1 - 1) * recordsPerPage + 1);
    setEndAssetRecord((currentAssetPage + 1) * recordsPerPage);
    let arr = [...paginationElements];
    arr.forEach((item) => {
      if (item.value === currentAssetPage + 1) {
        item.active = true;
      } else {
        item.active = false;
      }
    });
    setPaginationItems(arr);
    if (currentAssetPage + 1 === arr.length) {
      setDisableNext(true);
    } else {
      setDisableNext(false);
    }
    if (currentAssetPage + 1 === 1) {
      setDisablePrev(true);
    } else {
      setDisablePrev(false);
    }
  };
  const handleSelectRecordsPerPage = (item) => {
    let transactionsArr = [...filteredTransfers];
    let num = Math.ceil(transactionsArr.length / item.name);
    let pagiArr = [];
    for (let i = 1; i <= num; i++) {
      let item = { value: i, active: i === 1 ? true : false };
      pagiArr.push(item);
    }
    setCurrentAssetPage(1);
    setDisablePrev(true);
    if (pagiArr.length > 1) {
      setDisableNext(false);
    } else {
      setDisableNext(true);
    }
    setPaginationItems(pagiArr);
    setRecordsPerPage(item.name);
    setStartAssetRecord(1);
    setEndAssetRecord(item.name);
    let arr = [...dropdown];
    let paginArr = [];
    arr.map((drop, i) => {
      let a = {
        name: drop.name,
        checked: drop.name === item.name ? true : false,
      };
      paginArr.push(a);
    });
    setDropDownItems(paginArr);
  };
  function onTransFilterStringChange(e) {
    setLoading(true);
    setTransFilterString(e.target.value);

    var tempTransactions = [];
    var keyword = e.target.value.toUpperCase();
    var index = 0;
    transfers.map((transaction) => {
      if (
        transaction.from_account.toUpperCase().includes(keyword) ||
        transaction.to_account.toUpperCase().includes(keyword) ||
        transaction.amount.toUpperCase().includes(keyword) ||
        transaction.notes.toUpperCase().includes(keyword)
      ) {
        tempTransactions[index] = transaction;
        index = index + 1;
      }
    });
    setFilteredTransfers(tempTransactions);
    setLoading(false);
  }
  async function onTransactionDelete(id, transfer) {
    console.log(transfer);
    if (id !== undefined) {
      const result = await SwalWithBootstrapButtons.fire({
        icon: "error",
        title: "Confirm deletion!",
        text: "Are you sure you want to delete this Transfer?",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel!",
      });
      if (result.isConfirmed) {
        try {
          await db
            .collection("transfers")
            .doc(id)
            .delete()
            .then(() => {
              showConfirmationDialog(
                "This transfer successfully deleted from the list"
              );
            })
            .catch((error) => {
              console.log(error);
              SwalWithBootstrapButtons.fire(
                "Not deleted!",
                "Unable to delete the transaction",
                "warning"
              );
            });
        } catch (err) {
          console.log(err);
          await SwalWithBootstrapButtons.fire(
            "Failed!",
            "Unable to delete the transaction",
            "warning"
          );
        }
      }
    }
  }
  const handleEditOpen = (item) => {
    setEditTransfer(true);
    setEditTransferObj(item);
    setShowAddModal(true);
  };
  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <h4>Transfers</h4>
          <p className="mb-0">Account to account transfer transactions</p>
        </div>
        <div className="btn-toolbar mb-2 mb-md-0">
          <ButtonGroup>
            <Button variant="primary" size="sm" onClick={toggleModal}>
              <FontAwesomeIcon icon={faPaperPlane} className="me-2" /> Transfer
              Money
            </Button>
          </ButtonGroup>
          <AddNewTransferModal
            show={showAddModal}
            transDate={transDate}
            setTransactionDate={setTransactionDate}
            transactionDate={transactionDate}
            onHide={handleClose}
            transAmount={transAmount}
            transNotes={transNotes}
            performNewTransfer={performNewTransfer}
            fromBankAccount={fromBankAccount}
            setAccountIdByAccountName={setAccountIdByAccountName}
            toBankAccount={toBankAccount}
            accountsList={accountsList}
            editTransfer={editTransfer}
            editTransferObj={editTransferObj}
            performUpdateTransfer={performUpdateTransfer}
          />
        </div>
      </div>
      <div className="table-settings mb-4">
        <Row className="justify-content-between align-items-center">
          <Col xs={8} md={6} lg={3} xl={4}>
            <InputGroup>
              <InputGroup.Text>
                <FontAwesomeIcon icon={faSearch} />
              </InputGroup.Text>
              <Form.Control
                type="text"
                placeholder="Search"
                onKeyUp={onTransFilterStringChange}
              />
            </InputGroup>
          </Col>
          <Col xs={4} md={2} xl={1} className="ps-md-0 text-end">
            <Dropdown as={ButtonGroup}>
              <Dropdown.Toggle
                split
                as={Button}
                variant="link"
                className="text-dark m-0 p-0"
              >
                <span className="icon icon-sm icon-gray">
                  <FontAwesomeIcon icon={faCog} />
                </span>
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-menu-xs dropdown-menu-right">
                <Dropdown.Item className="fw-bold text-dark">
                  Show
                </Dropdown.Item>
                {dropdownItems.map((val) => {
                  if (val.checked) {
                    return (
                      <Dropdown.Item
                        className="d-flex fw-bold"
                        onClick={() => handleSelectRecordsPerPage(val)}
                      >
                        {val.name}{" "}
                        <span className="icon icon-small ms-auto">
                          <FontAwesomeIcon icon={faCheck} />
                        </span>
                      </Dropdown.Item>
                    );
                  } else {
                    return (
                      <Dropdown.Item
                        className="fw-bold"
                        onClick={() => handleSelectRecordsPerPage(val)}
                      >
                        {val.name}
                      </Dropdown.Item>
                    );
                  }
                })}
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        </Row>
      </div>

      {/* <TransactionsTable /> */}

      <Card border="light" className="table-wrapper table-responsive shadow-sm">
        {loading ? (
          <Loading />
        ) : (
          <>
            <Card.Body className="pt-0">
              <Table hover className="user-table align-items-center">
                <thead>
                  <tr>
                    <th className="border-bottom">Date</th>
                    <th className="border-bottom">From Account</th>
                    <th className="border-bottom">To Account</th>
                    <th className="border-bottom">Amount</th>
                    <th className="border-bottom">Comments/Notes</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredTransfers
                    .slice(startAssetRecord - 1, endAssetRecord)
                    .map((transfer) => (
                      <tr key={transfer.id}>
                        <td>
                          <span className="fw-normal">
                            {moment(transfer.date).format("MM/DD/YYYY")}
                          </span>
                        </td>
                        <td>
                          <span className="fw-normal">
                            {transfer.from_account}
                          </span>
                        </td>
                        <td>
                          <span className="fw-normal">
                            {transfer.to_account}
                          </span>
                        </td>
                        <td>
                          <span className="fw-normal">${transfer.amount}</span>
                        </td>
                        <td>
                          <span className="fw-normal">{transfer.notes}</span>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
              <Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between">
                <Pagination className="mb-2 mb-lg-0">
                  <Pagination.Prev disabled={disablePrev} onClick={handlePrev}>
                    Previous
                  </Pagination.Prev>
                  {paginationElements.length > 0 &&
                    paginationElements.map((item, index) => {
                      return (
                        <>
                          <Pagination.Item
                            value={item.value}
                            key={item.value}
                            onClick={(e) => handlePageChange(item, index)}
                            active={item.active}
                          >
                            {item.value}
                          </Pagination.Item>
                        </>
                      );
                    })}
                  <Pagination.Next disabled={disableNext} onClick={handleNext}>
                    Next
                  </Pagination.Next>
                </Pagination>
                {transfers.length > 0 && (
                  <small className="fw-bold">
                    Showing <b>{startAssetRecord}</b>
                    {" - "}
                    {endAssetRecord >= transfers.length && (
                      <b>{transfers.length}</b>
                    )}
                    {endAssetRecord < transfers.length && (
                      <b>{endAssetRecord}</b>
                    )}{" "}
                    of <b>{transfers.length}</b>
                  </small>
                )}
              </Card.Footer>
            </Card.Body>
          </>
        )}
      </Card>
    </>
  );
}

export default Transfers;
